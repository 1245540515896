import React, { Component } from 'react'
import PropTypes from 'prop-types';

// 样式
import './modal.less';

export default class extends Component {
    // props检查
	static propTypes = {
		maskClose: PropTypes.bool,
        transparent:PropTypes.bool,
        maskBg:PropTypes.string,
        isMobile:PropTypes.bool,
        fillWidth:PropTypes.bool,
	};
	// props默认值
	static defaultProps = {
        fillWidth:false,
		maskClose: true,
        transparent:false,
        maskBg:'rgba(0,0,0,0.6)',
        isMobile:false
	};
    onClose=()=>{
        if(this.props.maskClose){
            this.props.onClose()
        }
	}
    onClick = (e) => {
		e.stopPropagation();
	};
    componentDidMount(){
        if(this.props.show){
            setTimeout(()=>{
                document.body.style.overflow='hidden'
                document.body.style.height='100%'
            },1000)
        }else{
            document.body.style.overflow='auto'
            document.body.style.height='auto'
        }
    }
    componentDidUpdate(){
        if(this.props.show){
            setTimeout(()=>{
                document.body.style.overflow='hidden'
                document.body.style.height='100%'
            },1000)
        }else{
            document.body.style.overflow='auto'
            document.body.style.height='auto'
        }
    }
    componentWillUnmount(){
        document.body.style.overflow='auto'
        document.body.style.height='auto'
    }
    render() {
        return (
            this.props.show?<div className="c-modal" style={{background:this.props.maskBg}} onClick={this.onClose}>
                <div className={`show c-modal__content ${this.props.isMobile?'mobile':''}`} onClick={this.onClick} style={{backgroundColor:this.props.transparent?'':'#fff',width:this.props.fillWidth?'100%':'auto'}}>{this.props.children}</div>
            </div>:null
        )
    }
}