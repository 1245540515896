import React, { Component } from 'react'
/* import { connect } from 'react-redux' */
import { Link } from 'react-router-dom'
import { CNav } from '@components'

// 样式
import './index.less'

import arrowRight from '../../static/images/arrow-right.png'
import watch4 from '../../static/images/watch4.png'
import watch5 from '../../static/images/watch5.png'
import watch6 from '../../static/images/watch6.png'
import watch7 from '../../static/images/watch7.png'
import watch8 from '../../static/images/watch8.png'
import watch9 from '../../static/images/watch9.png'
import watchband1 from '../../static/images/watchband1.png'
import watchband2 from '../../static/images/watchband2.png'
import watchband3 from '../../static/images/watchband3.png'
import watchband4 from '../../static/images/watchband4.png'
import mask1 from '../../static/images/mask1.png'
import mode from '../../static/images/mode.png'
import Globe from '../../static/images/Globe.png'
// import device from '../../static/images/device.png'
import speed from '../../static/images/speed.png'
// import duration from '../../static/images/duration.png'
// import network from '../../static/images/network.png'
import concurrent from '../../static/images/concurrent.png'
import token from '../../static/images/token.png'
import logo from '../../static/images/logo.png'
import displayIcon from '../../static/images/display.png'
import thinIcon from '../../static/images/thin.png'
import atmIcon from '../../static/images/atm.png'

import in1 from '../../static/images/in1.png'
import in2 from '../../static/images/in2.png'

import watchtab1 from '../../static/images/watchtab1.png'
import watchtab2 from '../../static/images/watchtab2.png'
import watchtab3 from '../../static/images/watchtab3.png'
import watchtab4 from '../../static/images/watchtab4.png'
import watchtab5 from '../../static/images/watchtab5.png'
import watchtab6 from '../../static/images/watchtab6.png'
import watchtab7 from '../../static/images/watchtab7.png'
import watchtab8 from '../../static/images/watchtab8.png'

import watchtype1 from '../../static/images/watchtype1.png'
import watchtype2 from '../../static/images/watchtype2.png'
import watchtype3 from '../../static/images/watchtype3.png'
import watchtype4 from '../../static/images/watchtype4.png'
import watchtype5 from '../../static/images/watchtype5.png'
import watchtype6 from '../../static/images/watchtype6.png'
import watchtype7 from '../../static/images/watchtype7.png'
import watchtype8 from '../../static/images/watchtype8.png'

import leftIcon from '../../static/images/leftIcon.png'
import rightIcon from '../../static/images/rightIcon.png'

import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

//手机模型动画
function animateStep() {
    let el = gsap.timeline()
    el.fromTo(
        '.m1',
        {
            autoAlpha: 0
        },
        { autoAlpha: 1, duration: 2 }
    )
        .fromTo(
            '.m2',
            {
                autoAlpha: 0
            },
            { autoAlpha: 1, duration: 2 }
        )
        .fromTo(
            '.m3',
            {
                autoAlpha: 0
            },
            { autoAlpha: 1, duration: 2 }
        )
        .fromTo(
            '.m4',
            {
                autoAlpha: 0
            },
            { autoAlpha: 1, duration: 2 }
        )
        .fromTo(
            '.m5',
            {
                autoAlpha: 0
            },
            { autoAlpha: 1, duration: 2 }
        )
}

//字体动画
function animateFrom(elem, direction) {
    direction = direction || 1
    let x = 0,
        y = direction * 100
    if (elem.classList.contains('gs_reveal_fromLeft')) {
        x = -100
        y = 0
    } else if (elem.classList.contains('gs_reveal_fromRight')) {
        x = 100
        y = 0
    }
    elem.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
    elem.style.opacity = '0'
    gsap.fromTo(
        elem,
        { x: x, y: y, autoAlpha: 0 },
        {
            duration: 2,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: 'expo',
            overwrite: 'auto'
        }
    )
}

//隐藏元素
function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 })
}

const imgs = [watchtype1, watchtype2, watchtype3, watchtype4, watchtype5, watchtype6, watchtype7, watchtype8]
/* const mapStateToProps = () => {
    return {}
} 
@connect(mapStateToProps, mapDispatchToProps) */
class Index extends Component {
    state = {
        currentImg: watchtype4
    }
    componentDidMount() {
        this.initSwiper()
        this.initSwiperWatchband()
        this.initgsap()
        this.initText()
    }

    //表头轮播
    initSwiper = () => {
        let that = this
        let mySwiper = new Swiper('.swiper-container', {
            autoplay: false, //可选选项，自动滑动
            direction: 'horizontal',
            seed: 1000,
            loop: true, //可选选项，开启循环
            observer: true,
            observerParents: true,
            effect: 'coverflow',
            slidesPerView: 'auto',
            centeredSlides: true,
            slideToClickedSlide: true,
            notNextTick: true,
            //initialSlide: 3,
            coverflowEffect: {
                rotate: 0,
                stretch: -5,
                depth: 20,
                modifier: 5,
                slideShadows: false
            },
            on: {
                slideChangeTransitionEnd: function() {
                    //console.log('this.realIndex', this.realIndex, that)
                    let img = imgs.find((i, index) => {
                        if (index === this.realIndex) return i
                    })
                   // console.log('img', img)
                    that.setState({
                        currentImg: img
                    })
                },
                slideChange:function(){
                    let img = imgs.find((i, index) => {
                        if (index === this.realIndex) return i
                    })
                    that.setState({
                        currentImg: img
                    })
                }

            }
        })
        this.mySwiper = mySwiper
        console.log(mySwiper, this.mySwiper)
    }

    //表带轮播
    initSwiperWatchband = () => {
        let mySwiper = new Swiper('.p-content-color-select', {
            autoplay: false, //可选选项，自动滑动
            direction: 'horizontal',
            seed: 600,
            loop: true, //可选选项，开启循环
            observer: true,
            observerParents: true,
            effect: 'coverflow',
            slidesPerView: 'auto',
            centeredSlides: true,
            slideToClickedSlide: true,
            notNextTick: true,
            //touchRatio:0,
            //initialSlide:1,
            coverflowEffect: {
                rotate: 0,
                stretch: -5,
                depth: 20,
                modifier: 5,
                slideShadows: false
            },
            on: {
                slideChange: function() {
                    console.log('this.realIndex:', this.realIndex)
                },
                click: function(swiper, event) {
                    console.log('swiper', this.realIndex, swiper, event)
                }
            },
            navigation: {
                nextEl: '.swiper-btn-next',
                prevEl: '.swiper-btn-prev'
            }
            /* onSlideChangeEnd:(swiper)=>{
                console.log(swiper.realIndex)
            } */
        })
        console.log(mySwiper)
    }

    //手机模型动画
    initgsap = () => {
        ScrollTrigger.create({
            trigger: '.p-content-manuals',
            markers: false,
            onEnter: function() {
                animateStep()
            },
            onEnterBack: function() {
                animateStep()
            },
            onLeave: function() {
                hide('.m1')
                hide('.m2')
                hide('.m3')
                hide('.m4')
                hide('.m5')
            } // assure that the element is hidden when scrolled into view
        })
    }

    //字体动画
    initText = () => {
        gsap.utils.toArray('.gs_reveal').forEach(function(elem) {
            console.log('elm', elem)
            hide(elem) // assure that the element is hidden when scrolled into view

            ScrollTrigger.create({
                trigger: elem,
                markers: false,
                onEnter: function() {
                    animateFrom(elem)
                },
                onEnterBack: function() {
                    animateFrom(elem, -1)
                },
                onLeave: function() {
                    hide(elem)
                } // assure that the element is hidden when scrolled into view
            })
        })
    }

    //跳转
    handleLink=(url)=>{
       if(url) window.open(url)
    }

    render() {
        return (
            <div className="p-index">
                <CNav index={2}></CNav>

                <div className="p-content">
                    <div className="p-content-header">
                        <div>
                            <span>BEEWATCH</span>
                            <span onClick={()=>{this.handleLink('https://shop.beemobile.xyz/products/beewacth-w13')}}>Buy Now</span>
                        </div>
                    </div>

                    <div className="p-content-title gs_reveal">
                        <h3>BEEWACTH W13</h3>
                        <div>BEEWATCH is a large screen with high resolution, super clear experience. 1.8 inch TFT screen,</div>
                        <div>Resolution: 320*320.ultra-thin design, aluminum alloy, comfortable and light to wear</div>
                    </div>

                    <div className="p-content-btn">
                        <div onClick={()=>{this.props.history.push('/watch-detail')}}>
                            <span>Learn More</span>
                            <img src={arrowRight} alt="" style={{ width: '24px', height: '24px' }} />
                        </div>
                        {/* <div onClick={()=>{this.handleLink('https://shop.beemobile.xyz/products/beewacth-w13')}}>
                            <span >Buy Now</span>
                            <img src={arrowRight} alt="" style={{ width: '24px', height: '24px' }} />
                        </div> */}
                    </div>

                    <div className="p-content-bk1">
                        <h3 className="gs_reveal">3ATM waterproof grade. </h3>
                        <div className="gs_reveal">24-hour continuous heart rate monitoring. </div>
                        <div className="gs_reveal"> high-definition screen display and screen proportion. better visual experience.</div>
                        <div className="gs_reveal">free choice of multi dial style. blood oxygen monitoring.</div>
                    </div>

                    <div className="p-content-select">
                        <h3 className="gs_reveal">Select interface experience</h3>
                        <div className="p-content-select-text gs_reveal">PREVIEW INTERFACE</div>
                        <div className="p-content-select-imgs gs_reveal">
                            <img src={this.state.currentImg} alt="" />
                        </div>
                    </div>

                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            {[watchtab1, watchtab2, watchtab3, watchtab4, watchtab5, watchtab6, watchtab7, watchtab8].map((item, index) => {
                                return (
                                    <div className="swiper-slide" key={index}>
                                        <img src={item} alt="" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="p-content-color">
                        <h3 className="gs_reveal">New Colors</h3>
                        <div className="p-content-color-text gs_reveal">WELCOM TO BUY A NEW WATCH STRAP</div>
                        <div className="p-content-color-select">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide watchband">
                                    <img src={watchband1} alt="" />
                                   {/*  <img src={mask1} alt="" /> */}
                                </div>
                                <div className="swiper-slide watchband">
                                    <img src={watchband2} alt="" />
                                   {/*  <img src={mask1} alt="" /> */}
                                </div>
                                <div className="swiper-slide watchband">
                                    <img src={watchband3} alt="" />
                                   {/*  <img src={mask1} alt="" /> */}
                                </div>
                                <div className="swiper-slide watchband">
                                    <img src={watchband4} alt="" />
                                   {/*  <img src={mask1} alt="" /> */}
                                </div>
                            </div>
                            <div className="swiper-btn-prev">
                                <img src={leftIcon} alt="" />
                            </div>
                            <div className="swiper-btn-next">
                                <img src={rightIcon} alt="" />
                            </div>
                            <div className="watchCenter">
                            <img src={mask1} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="p-content-your">
                        <h3 className="gs_reveal">Select your</h3>
                        <div className="p-content-your-text gs_reveal">BEEWACTH W13</div>
                        <div className="p-content-your-production">
                            <div className="gs_reveal gs_reveal_fromLeft">
                                <img src={watch4} alt="" />
                                <div onClick={()=>{this.handleLink('https://shop.beemobile.xyz/products/beewacth-w13')}}>
                                    <span>Buy Now</span>
                                    <img src={arrowRight} alt="" style={{ width: '24px', height: '24px', marginLeft: '15px' }} />
                                </div>
                            </div>
                            <div className="item gs_reveal gs_reveal_fromRight">
                                <div onClick={()=>{this.handleLink('https://shop.beemobile.xyz/products/beewacth-w13')}}>
                                    <img src={watch5} alt="" />
                                    <div>
                                        <span>Buy Now</span>
                                        <img src={arrowRight} alt="" style={{ width: '24px', height: '24px', marginLeft: '15px' }} />
                                    </div>
                                </div>
                                <div onClick={()=>{this.handleLink('https://shop.beemobile.xyz/products/beewacth-w13')}}>
                                    <img src={watch6} alt="" />
                                    <div>
                                        <span>Buy Now</span>
                                        <img src={arrowRight} alt="" style={{ width: '24px', height: '24px', marginLeft: '15px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-content-device">
                        <img src={watch7} alt="" className="gs_reveal gs_reveal_fromLeft" />
                        <img src={watch9} alt="" />
                        <img src={watch8} alt="" />

                        <div className="p-content-device-right gs_reveal">
                            <h3>
                                DEVICE
                                <br /> FEATURES
                            </h3>
                            <ul>
                                <li>CPU：RTL8762DT</li>
                                <li>Pedometer：STK 8321</li>
                                <li>Memory：128MB</li>
                                <li>Bluetooth：BT 5.1</li>
                                <li>Display：1.8inch 320*390IPS-TFT</li>
                                <li>Watch body：Aluminum</li>
                                <li>Touchscreen：Capacitive full contact</li>
                                <li>Bottom shell：Dual color mold/plastic</li>
                                <li>Hear rate sensor：HX 3605</li>
                                <li>Watch band：silica gel</li>
                                <li>Product dimension： 45.5MM*37.9MM*12.6MM</li>
                                <li>
                                    Battery：330mAhhigh-voltagepolymerbatter，
                                    <br /> standby`{'>'}`20 days， use`{'>'}`10 days
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="p-content-manuals pc">
                        {/* <div className="p-content-manuals-mode">
                            <img src={mode} alt="" className="m1 animate__animated  animate__fadeIn  animate__slow" />
                            <img src={in1} alt="" className="m2 animate__animated  animate__fadeIn animate__delay-1s animate__slow" />
                            <img src={in2} alt="" className="m3 animate__animated  animate__fadeIn animate__delay-2s animate__slow" />
                            <div className="m4 animate__animated  animate__fadeInUp animate__delay-3s animate__slow"></div>
                            <div className="m5 animate__animated  animate__fadeInUp animate__delay-4s animate__slow"></div>
                        </div> */}
                        <div className="p-content-manuals-mode">
                            <img src={mode} alt="" className="m1" />
                            <img src={in1} alt="" className="m2" />
                            <img src={in2} alt="" className="m3" />
                            <div className="m4"></div>
                            <div className="m5"></div>
                        </div>
                        <div className="p-content-manuals-right gs_reveal">
                            <h3>PRODUCT USER MANUALS</h3>
                            <div>APP download and login</div>
                            <li>1.Apple mobile phones can be downloaded in the App Store.</li>
                            <li>2.Android phones can be downloaded in Google play store and app treaure.</li>
                            <li>3.Or scan the QR code in the watch to download the APP(compatible：IOS 11.0+or Android 6.0+)</li>
                            <li>Install the APP， please register and login according to the interface prompts， you need to turn on the Bluetooth.</li>
                            <li>In the device interface， select the Bluetooth name W13 for binding， and the watch can be used normally.</li>
                            <div onClick={()=>{this.props.history.push('/watch-detail')}}>
                                <span>VIEW</span>
                                <img src={arrowRight} alt="" style={{ width: '24px', height: '24px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="p-content-manuals mobile">
                        {/*  <div className="p-content-manuals-mode">
                            <img src={mode} alt="" className="m1 animate__animated  animate__fadeIn  animate__slow" />
                            <img src={in1} alt="" className="m2 animate__animated  animate__fadeIn animate__delay-1s animate__slow" />
                            <img src={in2} alt="" className="m3 animate__animated  animate__fadeIn animate__delay-2s animate__slow" />
                            <div className="m4 animate__animated  animate__fadeInUp animate__delay-3s animate__slow"></div>
                            <div className="m5 animate__animated  animate__fadeInUp animate__delay-4s animate__slow"></div>
                        </div> */}
                        <div className="p-content-manuals-mode">
                            <img src={mode} alt="" className="m1" />
                            <img src={in1} alt="" className="m2" />
                            <img src={in2} alt="" className="m3" />
                            <div className="m4"></div>
                            <div className="m5"></div>
                        </div>

                        <div className="mode-right">
                           
                            <div style={{ marginTop: '10px' }}>PRODUCT USER MANUALS</div>
                        </div>
                        <div className="bottom-text">
                            <div>
                                <h3>
                                    APP <br />
                                    download and login
                                </h3>
                                <li>APP download method “On Wear Pro”：</li>
                                <li>1.Apple mobile phones can be downloaded in the App Store.</li>
                                <li>2.Android phones can be downloaded in Google play store and app treaure.</li>
                            </div>
                            <div>
                                <li>3.Or scan the QR code in the watch to download the APP(compatible：IOS 11.0+or Android 6.0+) </li>
                                <li>Install the APP， please register and login according to the interface prompts， you need to turn on the Bluetooth. </li>
                                <li>In the device interface， select the Bluetooth name W13 for binding， and the watch can be used normally.</li>
                            </div>
                        </div>
                    </div>

                    <div className="p-content-bk3">
                        <div>
                            <li>
                                <img src={displayIcon} alt="" />
                                <span>Display</span>
                                <span>Large screen with high resolution, super clear experience.</span>
                            </li>
                            <li>
                                <img src={speed} alt="" />
                                <span>Ultra-long Duration</span>
                                <span>{`330mAh high-voltage polymer battery, standby > 20 days, use > 10 days`}</span>
                            </li>
                            <li>
                                <img src={thinIcon} alt="" />
                                <span>Ultra-thin Design</span>
                                <span>aluminum alloy, comfortable and light to wear.</span>
                            </li>
                            <li>
                                <img src={atmIcon} alt="" />
                                <span>3ATM</span>
                                <span>Strong waterproof.</span>
                            </li>
                            <li>
                                <img src={concurrent} alt="" />
                                <span>Powerful heart rate sensor</span>
                                <span>24-hour continuous heart rate monitoring.</span>
                            </li>
                            <li>
                                <img src={token} alt="" />
                                <span>Style</span>
                                <span>Free choice of multi dial style.</span>
                            </li>
                        </div>
                    </div>

                    <div className="p-content-footer pc">
                        <div>
                            <div>
								<Link to="/"><img src={logo} alt="" style={{ width: '65px', height: '39px' }} /></Link>
								<a href="https://shop.beemobile.xyz/collections/frontpage"><span>Products</span></a>
								<a href="https://shop.beemobile.xyz/pages/bulletin"><span>Bulletin</span></a>
								<a href="https://shop.beemobile.xyz/pages/support"><span>Support</span></a>
								<a href="https://shop.beemobile.xyz/pages/contact"><span>Contact Us</span></a>
                            </div>
                            <div>
                                <span>Copyright © Beelabs All Rights Reserved.</span>
                                <span>
                                    <img src={Globe} alt="" style={{ width: '24px', height: '24px' }} />
                                    <span>Hong Kong / English</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Index
