import Index from '@src/pages/index'
import Beefi from '@src/pages/beefi'
import Beewatch from '@src/pages/beewatch'
import Support from '@src/pages/support'
import Contact from '@src/pages/contact'
import WatchDetail from '@src/pages/watch-detail'
import BeefiDetail from '@src/pages/beefi-detail'

export default [
    {
        path: '/',
        title: 'Home',
        component: Index
    },
	{
        path: '/beefi',
        title: 'Beefi',
        component: Beefi
    },
	{
		path:'/beewatch',
		title: 'Beewatch',
        component: Beewatch
	},
	{
		path:'/support',
		title: 'Support',
        component: Support
	},
	{
		path:'/contact',
		title: 'Contact',
        component: Contact
	},
	{
		path:'/watch-detail',
		title:'WatchDetail',
		component:WatchDetail
	},
	{
		path:'/beefi-detail',
		title:'BeefiDetail',
		component:BeefiDetail
	}
]
