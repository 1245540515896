import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom'
// 样式
import './index.less'

const mapStateToProps = () => {
	return {}
};
const mapDispatchToProps = {

};
@connect(mapStateToProps,mapDispatchToProps)
class VideoBg extends Component{
	// props检查
	static propTypes = {
		imageArray:PropTypes.object,
		imgWidth:PropTypes.number,
		imgHeight:PropTypes.number,
		reverse:PropTypes.bool,
		layerIndex:PropTypes.string,
	};
	static defaultProps = {
		reverse:true,
	}
	refCanvas = React.createRef();
    state={
		direction:'up',
		index:0,
		images:{},// 图片对象
		playing:false,
    }
	// 预加载图片
	preloadImg = () => {
		const count = Object.keys(this.props.imageArray).length
		const loadedImgs={}
		for (let i = 0; i < count ; i++) {
			const img = new Image();
			img.src = this.props.imageArray[`p${i}`];
			img.onload=()=>{
				if(i===0){
					this.updateImg(0)
				}
				this.loadedNum=this.loadedNum+1
				if(this.loadedNum===count){
					this.draw()
				}
			}
			loadedImgs['img'+i]=img
		}
		this.setState({
			images:loadedImgs
		})
	}
	draw=()=>{
		this.setState({
			playing:true
		})
		const count = Object.keys(this.props.imageArray).length
		this.timer=setInterval(()=>{
			const {direction,index}=this.state
			let curIndex;
			if(direction==='up'){
				if(index>=count-1){
					if(this.props.reverse){
						curIndex=count-2
						this.setState({
							direction:'down',
							index:curIndex
						})
					}else{
						curIndex=0
						this.setState({
							index:curIndex
						})
					}
					
				}else{
					curIndex=index+1
					this.setState({
						index:curIndex
					})
				}
			}else if(direction==='down'){
				if(index<=0){
					curIndex=1
					this.setState({
						direction:'up',
						index:curIndex
					})
				}else{
					curIndex=index-1
					this.setState({
						index:curIndex
					})
				}
			}
			this.updateImg(curIndex)
		},60)
	}
	// 更新绘制
	updateImg = index => {
		const canvas = this.refCanvas.current
		const ctx = canvas.getContext("2d")
		canvas.width=this.props.imgWidth
		canvas.height=this.props.imgHeight
		ctx.clearRect(0, 0,this.props.imgWidth, this.props.imgHeight,0,0, this.props.imgWidth, this.props.imgHeight)		
		const img=this.state.images['img'+index]
		ctx.drawImage(img, 0, 0,this.props.imgWidth, this.props.imgHeight,0,0, this.props.imgWidth, this.props.imgHeight);
		img.onload=()=>{
			ctx.drawImage(img, 0, 0,this.props.imgWidth, this.props.imgHeight,0,0, this.props.imgWidth, this.props.imgHeight);
		}
	}

	handleScale=()=>{
		let scale
		if(this.props.imgWidth/this.props.imgHeight>this.refCanvas.current.parentNode.offsetWidth/this.refCanvas.current.parentNode.offsetHeight){
			scale=this.refCanvas.current.parentNode.offsetHeight/this.props.imgHeight
		}else{
			scale=this.refCanvas.current.parentNode.offsetWidth/this.props.imgWidth
		}
		this.refCanvas.current.style.transform=`translate(-50%,-50%) scale(${scale})`
	}
	handleScroll=()=>{
		const ele=document.getElementById(this.props.trigger)
		const start=window.innerHeight
		const {top,bottom}=ele.getBoundingClientRect()
		const count = Object.keys(this.props.imageArray).length
		if(top>start||bottom<0){
			clearInterval(this.timer)
			this.timer=null
			this.setState({
				playing:false
			})
		}else{
			if(this.state.playing){
				return
			}
			if(this.loadedNum===count){
				this.draw()
			}
		}
	}
	componentDidMount(){
		this.loadedNum=0
		this.preloadImg()
		this.handleScale()
		this.handleScroll()
		window.addEventListener('resize',this.handleScale,false)
		window.addEventListener('scroll',this.handleScroll,false)
	}
	componentWillUnmount(){
		clearInterval(this.timer)
		this.timer=null
		window.removeEventListener('resize',this.handleScale,false)
		window.removeEventListener('scroll',this.handleScroll,false)
	}
    render(){
        return <canvas className='video-bg' style={{zIndex:this.props.layerIndex?this.props.layerIndex:'1'}} ref={this.refCanvas} />
    }
}
export default VideoBg