import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import {CNav,CFooter,CVideoBg} from '@components'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import logo1 from '../../static/logo1.png'
import logo2 from '../../static/logo2.png'
import logo3 from '../../static/logo3.png'
import logo4 from '../../static/logo4.png'
import logo5 from '../../static/logo5.png'
import * as bannerImageArray from './bannerbg.js';
import * as cryptoImageArray from './cryptobg.js';
import * as machineImageArray from './machinebg.js';
import * as beefiImageArray from './beefibg.js';
import * as termImageArray from './termbg.js';
import * as serviceImageArray from './servicebg.js';
import beeFi from '../../static/product-img-beefi.png'
import beeWatch from '../../static/product-img-watch.png'
import newsText from '../../static/bg-news.png'
import newsText2 from '../../static/bg-news2.png'
import newsText3 from '../../static/bg-news3.png'
import watch from '../../static/index-watch.png'
import beefi from '../../static/index-beefi.png'
import iconBee from '../../static/img-index-bee.png'

gsap.registerPlugin(ScrollTrigger);
// 样式
import './index.less'

const opacity1='1'
const opacity2='0.2'
const opacity3='0.1'
const opacity4='0.05'
const opacityMap={
	'0-0':opacity4,
	'0-1':opacity4,
	'0-2':opacity4,
	'0-3':opacity4,
	'0-4':opacity3,
	'0-5':opacity3,
	'0-6':opacity3,
	'0-7':opacity3,
	'0-8':opacity4,
	'0-9':opacity4,
	'0-10':opacity4,
	'1-0':opacity4,
	'1-1':opacity4,
	'1-2':opacity4,
	'1-3':opacity3,
	'1-4':opacity2,
	'1-5':opacity2,
	'1-6':opacity2,
	'1-7':opacity3,
	'1-8':opacity4,
	'1-9':opacity4,
	'1-10':opacity4,	
	'2-0':opacity4,
	'2-1':opacity4,
	'2-2':opacity4,
	'2-3':opacity3,
	'2-4':opacity2,
	'2-5':opacity1,
	'2-6':opacity1,
	'2-7':opacity2,
	'2-8':opacity3,
	'2-9':opacity4,
	'2-10':opacity4,
	'3-0':opacity4,
	'3-1':opacity4,
	'3-2':opacity3,
	'3-3':opacity2,
	'3-4':opacity1,
	'3-5':opacity1,
	'3-6':opacity1,
	'3-7':opacity2,
	'3-8':opacity3,
	'3-9':opacity4,
	'3-10':opacity4,
	'4-0':opacity4,
	'4-1':opacity4,
	'4-2':opacity4,
	'4-3':opacity3,
	'4-4':opacity2,
	'4-5':opacity1,
	'4-6':opacity1,
	'4-7':opacity2,
	'4-8':opacity3,
	'4-9':opacity4,
	'4-10':opacity4,
	'5-0':opacity4,
	'5-1':opacity4,
	'5-2':opacity4,
	'5-3':opacity3,
	'5-4':opacity2,
	'5-5':opacity2,
	'5-6':opacity2,
	'5-7':opacity3,
	'5-8':opacity4,
	'5-9':opacity4,
	'5-10':opacity4,
	'6-0':opacity4,
	'6-1':opacity4,
	'6-2':opacity4,
	'6-3':opacity4,
	'6-4':opacity3,
	'6-5':opacity3,
	'6-6':opacity3,
	'6-7':opacity3,
	'6-8':opacity4,
	'6-9':opacity4,
	'6-10':opacity4,
}

const mapStateToProps = () => {
	return {}
};
const mapDispatchToProps = {

};
@connect(mapStateToProps,mapDispatchToProps)
class Index extends Component{
    state={
		row:7,// 总行数
        column:11,//总列数 
		logoWidth:130,
		logoHeight:109,
		p1:120,
		p2:20,
    }
	getOpacity=(row,column)=>{
		return opacityMap[`${row}-${column}`]
	}
	getPosition=(row,column)=>{
		let x,y
		if(row<4&&column<6){
			x='-100vw'
			y='-100vh'
		}
		if(row<4&&column>6){
			x='100vw'
			y='-100vh'
		}
		if(row===4&&column<6){
			x='-100vw'
			y='0'
		}
		if(row===4&&column>6){
			x='100vw'
			y='0'
		}
		if(row>4&&column<6){
			x='-100vw'
			y='100vh'
		}
		if(row>4&&column>6){
			x='100vw'
			y='100vh'
		}
		if(row<4&&column===6){
			x='0'
			y='-100vh'
		}
		if(row>4&&column===6){
			x='0'
			y='100vh'
		}
		return {
			x,
			y
		}
	}
	handleLogoSize=()=>{
		if(window.innerWidth>=768){
			this.setState({
				logoWidth:280,
				logoHeight:226,
				p1:120,
				p2:20,
			})
		}else{
			this.setState({
				logoWidth:130,
				logoHeight:109,
				p1:65,
				p2:0,
			})
		}
	}
	bannerAnimation=()=>{
		// 文字隐藏
		gsap.timeline({
			scrollTrigger: {
				trigger: ".p-home__banner",
				start:"top top",
				end:'+=200',
				scrub: true,
			}
		})
		.to('.layer-slogan>.title,.layer-slogan>.sub-title',{
			opacity:0,
			ease: "expo",
		})
		// logo下移
		gsap.timeline({
			scrollTrigger: {
				trigger: ".p-home__banner",
				start:"top top",
				end:'+=200',
				scrub: true,
			}
		})
		.to('.layer-logos-content .logo.up',{
			y:0,
			ease: "expo",
		})

		gsap.utils.toArray(".logo-to-center").forEach((logo) => {
			gsap.timeline({
				scrollTrigger:{
					trigger: ".p-home__banner",
					start: "top top", 
					end:`+=${200*(1.5-logo.dataset.opacity)}`,
					scrub: true
				}
			})
			.to(logo,{
				x:0,
				y:0,
				opacity:logo.dataset.opacity,
				ease: "expo"
			})
		});
	}
	layerBgAnimation=()=>{
		gsap.utils.toArray(".layer-bg").forEach((bg) => {
			const trigger=bg.parentNode;
			gsap.fromTo(
				bg, 
				{
					y: window.innerHeight
				}, 
				{
					y: 0,
					ease: "expo",
					scrollTrigger: {
						trigger,
						start:"top bottom" , 
						end: "top top",
						scrub: true,
						invalidateOnRefresh: true // to make it responsive
					}
				}
			);
		});
	}
	fadeInAnimation=()=>{	
		gsap.utils.toArray(".fade-in").forEach((dom) => {
			// const trigger=dom.dataset.trigger
			const y=dom.dataset.y
			const delay=parseFloat(dom.dataset.delay)
			gsap.timeline({
				scrollTrigger:{
					trigger:dom,
					start: "top bottom", 
					end:"bottom 90%",
					scrub: true,
				}
			})
			.from(dom,{
				y,
				delay,
				opacity:0,
				duration:1,
				ease: "none"
			})
		})
	}
	lineAnimation=()=>{
		gsap.timeline({
			scrollTrigger: {
				trigger: "#news",
				start:"top 300" , 
				end: "+=200",
				scrub: true,
			}
		})
		.from("#news .book",  { y: 300,opacity:0,ease: "expo" })
		.from("#news .sq3",  { y: 300,opacity:0,ease: "expo" })
		.from("#news .sq2",  { y: 300,opacity:0,ease: "expo" })
		.from("#news .sq1",  { y: 300,opacity:0,ease: "expo" })
		gsap.timeline({
			scrollTrigger: {
				trigger: "#machine",
				start:"top 300" , 
				end: "+=200",
				scrub: true,
			}
		})
		.from("#machine .sq5",  { y: 300,opacity:0,ease: "expo" })
		.from("#machine .sq6",  { y: 300,opacity:0,ease: "expo" })
		.from("#machine .sq4",  { y: 300,opacity:0,ease: "expo" })
		gsap.timeline({
			scrollTrigger: {
				trigger: "#term",
				start:"top 300" , 
				end: "+=200",
				scrub: true,
			}
		})
		.from("#term .sq8",  { y: 300,opacity:0,ease: "expo" })
		.from("#term .sq7",  { y: 300,opacity:0,ease: "expo" })
		.from("#term .sq9",  { y: 300,opacity:0,ease: "expo" })
	}
	componentDidMount(){
		// banner动画
		this.bannerAnimation()
		// 背景文字动画
		this.layerBgAnimation()
		// 层渐入动画
		this.fadeInAnimation()
		// 线性动画
		this.lineAnimation()
		this.handleLogoSize()
		window.addEventListener('resize',this.handleLogoSize,false)
	}
	componentWillUnmount(){
		window.removeEventListener('resize',this.handleLogoSize,false)
	}
    render(){
		const l1=<img className='logo1' src={logo1}/>
		const l2=<img className='logo2' src={logo2}/>
		const l3=<img className='logo3' src={logo3}/>
		const l4=<img className='logo4' src={logo4}/>
		const l5=<img className='logo5' src={logo5}/>
		const l6=<div className='logo-coming'>In development...</div>
		const l7=<div className='logo-coming'>Coming soon...</div>
		const logos={
			'2-5':l1,
			'2-6':l2,
			'3-4':l3,
			'3-5':l4,
			'3-6':l5,
			'4-5':l6,
			'4-6':l7,
		}

        return <div className="p-home">
			<CNav index={0}></CNav>
				<div className="p-home__banner" id="banner">
					<div className='layer-sticky'>
						{/* video background */}
						<div className='layer-video'>
							<CVideoBg name="banner-video" trigger="banner" imgWidth={1600} imgHeight={900} imageArray={bannerImageArray}/>
						</div>
						{/* slogan */}
						<div className='layer-slogan'>
							<div className='logo'>{l4}</div>
							<div className='title'>wEB 3.0</div>
							<div className='sub-title'>made on earth for Humanity</div>
						</div>
						{/* logo */}
						<div className='layer-logos' style={{width:this.state.logoWidth*this.state.column+this.state.p2*2+'px',height:this.state.logoHeight*(this.state.row-1)+this.state.logoWidth+'px'}}>
							<div className='layer-logos-content'>
								{Array.from({length:this.state.row}).map((item,row)=>{
									return Array.from({length:this.state.column}).map((item,column)=><div data-opacity={this.getOpacity(row,column)} style={{transform:`translate(${this.getPosition(row+1,column+1).x},${this.getPosition(row+1,column+1).y})`,left:column*this.state.logoWidth+(row%2===1?this.state.p2:-this.state.p1)+'px',top:row*this.state.logoHeight+'px'}} key={`${row}-${column}`} className={`logo ${row===3&&column===5?'up':'logo-to-center'} ${logos[`${row}-${column}`]?'active':''}`}>{logos[`${row}-${column}`]}</div>)
								})}
							</div>
						</div>
					</div>
				</div>
				<div className='p-home__product'>
					<div className='product-content'>
						<div className="product-item item1">
							<div className="img-wrap fade-in" data-y="100" data-delay="0.1"><img className='img-wifi' src={beeFi}/></div>
							<div className='light-text fade-in' data-y="10" data-delay="0.2">up to 300Mbps</div>
							<div className='main-text fade-in' data-y="20" data-delay="0.4">bEEFI X3</div>
							<div className='side-text fade-in' data-y="30" data-delay="0.6">wireless device</div>
							<div className='btns fade-in' data-y="40" data-delay="0.8">
								{/* <div className='btn-item btn-light'>learn more</div> */}
								<a href="https://shop.beemobile.xyz/products/beefi-x3"><div className='btn-item btn-normal'>buy now</div></a>
							</div>
							<div className='hint' style={{opacity:0}}></div>
						</div>
						<div className="product-item">
							<div className="img-wrap fade-in" data-y="100" data-delay="0.1"><img  className='img-watch' src={beeWatch}/></div>
							<div className='light-text fade-in' data-y="10" data-delay="0.2">resolution: 320*320</div>
							<div className='main-text fade-in' data-y="20" data-delay="0.4">BEEWACTH W13</div>
							<div className='side-text fade-in' data-y="30" data-delay="0.6">aluminum alloy</div>
							<div className='btns fade-in' data-y="40" data-delay="0.8">
								{/* <div className='btn-item btn-light'>learn more</div> */}
								<a href="https://shop.beemobile.xyz/products/beewacth-w13"><div className='btn-item btn-normal'>buy now</div></a>
							</div>
						</div>
					</div>
				</div>
				<div className='p-home__news' id="news">
					<img className='layer-bg' src={newsText}/>
					<div className='news-content'>
						<div className='img-area'>
							<div className='book'></div>
							<div className='sq sq1'></div>
							<div className='sq sq2'></div>
							<div className='sq sq3'></div>
						</div>
						<div className='text'>
							<div className="text-content">
								<div className='text-main fade-in' data-y="100" data-delay="0.1">Is Crypto Mining Still Worth It:<br/> Best Way To Mine Crypto For Home Miners</div>
								<div className='text-side fade-in' data-y="100" data-delay="0.2">When cryptocurrency mining first began in 2009 with Bitcoin, it was very profitable. Early adopters got the best out of mining. However, as more time went by, the question arose of whether cryptocurrency mining with mining rigs was still profitable for crypto enthusiasts today.</div>
							</div>
						</div>
					</div>
				</div>
				<div className='p-home__crypto' id="crypto">
					<CVideoBg  name="second-video" trigger="crypto" imgWidth={2000} imgHeight={1000} imageArray={cryptoImageArray}/>
					<div className='video-up-content'>
						<img className='watch fade-in' data-y="100" data-delay="0.2" src={watch}/>
						<div className='watch-des'>
							<div className='watch-des-name fade-in' data-y="100" data-delay="0.4">BEEWACTH W13</div>
							<div className='watch-des-detail fade-in' data-y="100" data-delay="0.6">3ATM waterproof grade. 24-hour continuous heart rate monitoring. high-definition screen display and screen proportion. better visual experience. free choice of multi dial style. blood oxygen monitoring.
Cash-Sale</div>
							<Link to="/watch-detail"><div className='watch-des-link fade-in' data-y="100" data-delay="1">learn more</div></Link>
						</div>
					</div>
				</div>
				<div className='p-home__news' id="machine">
					<img className='layer-bg' src={newsText2}/>
					<div className='news-content reverse'>
						<div className='half'>
							<div className='factory-text'>
								<div className='text-main fade-in' data-y="100" data-delay="0.5">Opening Our Largest Factory to Date + Shipping </div>
								<div className='text-side fade-in' data-y="100" data-delay="1">In keeping with our promise to always maintain open and honest communication with our customers, we wanted to share some big news related to our production capacity, as well as provide some information that explains why some of our most recent batches have been slightly behind our estimated 20-week delivery window.</div>
							</div>
						</div>
						<div className='half'>
							<div className='factory-video'>
								<CVideoBg  name="third-video" trigger="machine" layerIndex="6" reverse={false} imgWidth={1000} imgHeight={750} imageArray={machineImageArray}/>
								<div className='sq sq4'></div>
								<div className='sq sq5'></div>
								<div className='sq sq6'></div>
							</div>
						</div>
					</div>
				</div>
				<div className='p-home__crypto' id="beefi">
					<CVideoBg  name="four-video" trigger="beefi" imgWidth={2000} imgHeight={1000} imageArray={beefiImageArray}/>
					<div className='video-up-content'>
						<img className='beefi fade-in' data-y="100" data-delay="0.2" src={beefi}/>
						<div className='watch-des'>
							<div className='watch-des-name fade-in' data-y="100" data-delay="0.4">BEEFI X3</div>
							<div className='watch-des-detail fade-in' data-y="100" data-delay="0.6">BEE is a small battery powered device that can maintain up to 50 concurrent client connections. 4G mini modem device supports mobile power function.</div>
							<Link to="/beefi-detail"><div className='watch-des-link fade-in' data-y="100" data-delay="1">learn more</div></Link>
						</div>
					</div>
				</div>
				<div className='p-home__news' id="term">
					<img className='layer-bg' src={newsText3}/>
					<div className='news-content reverse'>
						<div className='half'>
							<div className='factory-text'>
								<div className='text-main fade-in' data-y="100" data-delay="0.5">BeeFi, More Than Just Miners </div>
								<div className='text-side fade-in' data-y="100" data-delay="1">We take pride not only in the sheer volume of miners shipped, but much more so in our long-term commitment to our customers, the community, and the ecosystem. </div>
							</div>
						</div>
						<div className='half'>
							<div className='bee-video'>
								<CVideoBg  name="fifth-video" trigger="term" layerIndex="6" imgWidth={800} imgHeight={560} imageArray={termImageArray}/>
								<div className='sq sq7'></div>
								<div className='sq sq8'></div>
								<div className='sq sq9'></div>
							</div>
						</div>
					</div>
				</div>
				<div className='p-home__service' id="service">
					<CVideoBg  name="sixth-video" trigger="service" imgWidth={1920} imgHeight={1080} imageArray={serviceImageArray}/>
					<div className='service-content'>
						<div className='service-item'>
							<img src={iconBee} className='service-item-icon fade-in'  data-y="100" data-delay="0.2"/>
							<div className='service-item-name fade-in' data-y="100" data-delay="0.5">Made on Earth for Humanity</div>
						</div>
					</div>
				</div>
			<CFooter/>
		</div>
    }
}
export default Index