import React,{Component} from 'react'
import {connect} from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import routes from '@src/router'
import ScrollToTop from './scroll'

const mapStateToProps = () => {
	return {}
};
@connect(mapStateToProps)
class App extends Component {
    render(){
        return (
            <Router>
				<ScrollToTop />
                <Switch>
                    {routes.map(item => (
                        <Route exact={item.path === '/' ? true : false} path={item.path} key={item.path} component={item.component}></Route>
                    ))}
                </Switch>
            </Router>
        )
    }
}
export default App