import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import {CNav,CFooter,CVideoAnimation} from '@components'
import beefiModel from '../../static/front.png'
import p1 from '../../static/p1.png'
import p2 from '../../static/p2.png'
import p3 from '../../static/p3.png'
import p4 from '../../static/p4.png'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);
// 样式
import './index.less'

const mapStateToProps = () => {
	return {}
};
const mapDispatchToProps = {

};
@connect(mapStateToProps,mapDispatchToProps)
class Index extends Component{
    state={
        videoWidth:760,
		videoHeight:760,
		size:'large',
		speedBgStyle:{},
		speedBgStyle2:{},
		speedBgStyle3:{},
    }
	handleInit=()=>{
		const w=window.innerWidth
		if(w<480){
			this.setState({
				videoWidth:360,
				videoHeight:360,
                size:'small'
			})
		}else if(w>=480&w<768){
			this.setState({
				videoWidth:470,
				videoHeight:470,
                size:'medium'
			})
		}else{
			this.setState({
				videoWidth:760,
				videoHeight:760,
                size:'large'
			})
		}
		const speedBgStyle=this.setSize(2880,1860)
		const speedBgStyle2=this.setSize(2880,1629)
		const speedBgStyle3=this.setSize(2880,1456)
		this.setState({
			speedBgStyle,
			speedBgStyle2,
			speedBgStyle3
		})
	}
	setSize=(naturalWidth,naturalHeight)=>{
        return naturalWidth/naturalHeight>window.innerWidth/window.innerHeight?{
			backgroundSize:'auto 100%'
		}:{
			backgroundSize:'100%'
		}
    }
	componentDidMount(){
		// banner
		gsap.timeline({
			scrollTrigger: {
				trigger: ".p-beefi__banner",
			}
		})
		.from(".p-beefi__banner .title",  { y: 150,opacity:0,duration:1, })
		.from(".p-beefi__banner .slogan",  { y: 150,opacity:0,duration:1, })
		.from(".beefi-des-link",  { y: 150,opacity:0,duration:1, })
		// 
		gsap.timeline({
			scrollTrigger: {
				trigger: ".p-beefi__desc",
			}
		})
		.from(".beefi-model",  {x:-innerWidth*0.5, y: innerHeight * 0.5,duration:1 })

		gsap.timeline({
			scrollTrigger: {
				trigger: ".p-beefi__desc",
				start: "top center",
				end: "top top",
			}
		})
		.from(".beefi-description .title",  {y: 50,opacity:0 })
		.from(".beefi-description .description",  {y: 50,opacity:0 })
		setTimeout(()=>{
			gsap.timeline({
				scrollTrigger: {
					trigger: ".p-beefi__performance",
					start: "top 100",
					end: "+=300",
					scrub: true,
					pin: true,
				}
			})
			.from(".video-wrap .line1",  {scaleY:0,transformOrigin: "left bottom",duration:1})
			.from(".video-wrap .line2",  {scaleX:0,transformOrigin: "left top",duration:2})
			.from(".video-wrap .line3",  {scaleY:0,transformOrigin: "top right",duration:1})
			.from(".video-wrap .text1",  {opacity:0,duration:1})
			.from(".video-wrap .line4",  {scaleX:0,transformOrigin: "left top",duration:1})
			.from(".video-wrap .line5",  {scaleY:0,transformOrigin: "left top",duration:2})
			.from(".video-wrap .line6",  {scaleX:0,transformOrigin: "right top",duration:1})
			.from(".video-wrap .text2",  {opacity:0,duration:1})
		},1000)

		gsap.timeline({
			scrollTrigger: {
				trigger: ".p-beefi__speed",
				start: "top 100",
				end: "+=300",
				scrub: true,
			}
		})
		.from(".p-beefi__speed .content .title",  { y: 100,opacity:0,duration:1 })
		.from(".p-beefi__speed .content .desc",  { y: 100,opacity:0,duration:1 })
		gsap.timeline({
			scrollTrigger: {
				trigger: ".p-beefi__speed2",
				start: "top 100",
				end: "+=300",
				scrub: true,
			}
		})
		.from(".p-beefi__speed2 .content .title",  { y: 100,opacity:0,duration:1 })
		.from(".p-beefi__speed2 .content .desc",  { y: 100,opacity:0,duration:1 })

		gsap.utils.toArray(".item-bar-inner").forEach((bar) => {
			gsap.timeline({
				scrollTrigger:{
					trigger:'.p-beefi__render',
					start: "top 200", 
					end:`+=400`,
				}
			})
			.from(bar,{
				scaleX:0,
				transformOrigin: "left top",
				duration:1,
			})
		})
		this.handleInit()
		window.addEventListener('resize', this.handleInit,false);
	}
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleInit,false);
	}
    render(){
        return <div className="p-beefi">
			<CNav index={1}></CNav>
			<div className='fix-buy'>
				<div className='fix-buy-content'>
					<div className='name'>BEEFI X3</div>
					<a href="https://shop.beemobile.xyz/products/beefi-x3"><div className='fix-buy-btn'>buy now</div></a>
				</div>
			</div>
			<div className='p-beefi__banner'>
				<div className='title'>BEEFI X3</div>
				<div className='slogan'>biggest pre sale inventory of the year</div>
				<img src={p3} className='product'/>
				<Link to="/beefi-detail"><div className='beefi-des-link'>learn more</div></Link>
			</div>
			<CVideoAnimation preFps={2400} afterFps={2000} fps={20} imgWidth={1280} imgHeight={1280} size={this.state.size} width={this.state.videoWidth} height={this.state.videoHeight}/>
			<div className='p-beefi__desc'>
				<div className='column-half-end'>
					<img className='beefi-model' src={beefiModel}/>
				</div>
				<div className='column-half-start'>
					<div className='beefi-description'>
						<div className='title'>BEEFI X3</div>
						<div className='description'>BEE is a small battery powered device that can <br/>maintain up to 50 concurrent client <br/>connections.4G mini modem device supports <br/>mobile power function.</div>
					</div>
				</div>
			</div>
			<div className='p-beefi__performance'>
				<div className='title left-text'>Small size<br/>
high performance</div>
				<div className='video-wrap'>
					<img className='product' src={p4}/>
					<div className='line1'></div>
					<div className='line2'></div>
					<div className='line3'></div>
					<div className='line4'></div>
					<div className='line5'></div>
					<div className='line6'></div>
					<div className='text text1'>12 cm</div>
					<div className='text text2'>3 cm</div>
				</div>
			</div>
			<div className='p-beefi__feature'>
				<div className='title'>DEVICE FEATURES</div>
				<div className='feature-wrap'>
					<div className='feature-column'>
						<div className='feature-item'>LTE Wireless Portable Device LEDE/Wicrypt
OS 802.11n 300Mbps Wireless Device
3G&4G WiFi Device with Sim card slot</div>
						<div className='feature-item'>Fast and stable Wi-Fi devices. Up to
100Mbps wireless speed.LTE modem For4G
network with CAT4 150M downstream
bandwidth.</div>
						<div className='feature-item'>Support LTE Frequency Band：
FDD-LTE：B1/B3/B7/B8/B20/B28A
TDD-LTE：B38/B40/B41
WCDMA：B1/B8
GSM/EDGE：B3/B8</div>
					</div>
					<div className='feature-column'>
						<div className='feature-item'>RAM：128Mb， Upgradable to 4GB RAM
Allows connectivity of 50 concurrent users
Covers a distance of about 100m</div>
						<div className='feature-item'>WNT(Wi crypt Network Token) Mining Enabled</div>
						<div className='feature-item'>GPS Enabled</div>
						<div className='feature-item'>1 USB Interface</div>
						<div className='feature-item'>Low Power Enabled：5V1A</div>
						<div className='feature-item'>Battery Capacity：5000mAH</div>
						<div className='feature-item'>1 Micro Sim Slot</div>
					</div>
				</div>
			</div>
			<div className='p-beefi__speed' style={this.state.speedBgStyle}>
				<div className='content'>
					<div className='title'>Fastest</div>
					<p className='desc'>
						Support simultaneous playback of up to 9 tracks 8K ProRes video streaming4<br/><br/>
						57 billion transistors<br/><br/>
						Up to 11 trillion operations per second
					</p>
				</div>
			</div>
			<div className='p-beefi__speed2' style={this.state.speedBgStyle2}>
				<div className='content'>
					<div className='title'>Safety</div>
					<p className='desc'>
						Supports up to 5 external monitors<br/><br/>
						Supports H.264, HEVC and ProRes codecs<br/><br/>
						Up to 8TB SSD Up to 7.4GB/s read speed5
					</p>
				</div>
			</div>
			<div className='p-beefi__render'>
				<div className='content'>
					<div className='title'>In this high-energy space, everything is hardcore.</div>
					<div className='desc'>8K rendering speed increased by 10</div>
					<div className='item-wrap'>
						<div className='item'>
							<div className='item-bar'>
								<div className='item-bar-inner item-bar1'>
								</div>
							</div>
							<div className='item-text'>5x</div>
						</div>
						<div className='remark'>bEEFI X3</div>
					</div>
					<div className='item-wrap'>
						<div className='item'>
							<div className='item-bar'>
								<div className='item-bar-inner item-bar2'>
								</div>
							</div>
							<div className='item-text'>3.5x</div>
						</div>
						<div className='remark'>bEEFI X3</div>
					</div>
					<div className='item-wrap'>
						<div className='item'>
							<div className='item-bar'>
								<div className='item-bar-inner item-bar3'>
								</div>
							</div>
							<div className='item-text'>1.4x</div>
						</div>
						<div className='remark'>bEEFI X3</div>
					</div>
				</div>
			</div>
			<div className='p-beefi__advantage' style={this.state.speedBgStyle3}>
				<div className='advantage-content'>
					<div className='advantage-item'>
						<div className='advantage-item-icon icon-ad1'></div>
						<div className='advantage-item-title'>Wireless Device</div>
						<div className='advantage-item-intro'>3G 4G WiFi Device With Sim
card slot.</div>
					</div>
					<div className='advantage-item'>
						<div className='advantage-item-icon icon-ad2'></div>
						<div className='advantage-item-title'>Wireless Speed</div>
						<div className='advantage-item-intro'>Up to 300Mbps.</div>
					</div>
					<div className='advantage-item'>
						<div className='advantage-item-icon icon-ad3'></div>
						<div className='advantage-item-title'>Ultra-long Duration</div>
						<div className='advantage-item-intro'>{`use > 10 days`}</div>
					</div>
					<div className='advantage-item'>
						<div className='advantage-item-icon icon-ad4'></div>
						<div className='advantage-item-title'>Network</div>
						<div className='advantage-item-intro'>LTE modem For4G network with
CAT4 150M downstream
bandwidth.</div>
					</div>
					<div className='advantage-item'>
						<div className='advantage-item-icon icon-ad5'></div>
						<div className='advantage-item-title'>Concurrent</div>
						<div className='advantage-item-intro'>Allows connectivity of 70
concurrent users.</div>
					</div>
					<div className='advantage-item'>
						<div className='advantage-item-icon icon-ad6'></div>
						<div className='advantage-item-title'>Token</div>
						<div className='advantage-item-intro'>WNT Mining Enabled.</div>
					</div>
				</div>
			</div>
			<div className='p-beefi__product'>
				<div className="product-item">
					<div className='light-text'>up to 300Mbps</div>
					<div className='main-text'>bEEFI X3</div>
					<div className='side-text'>wireless device</div>
					<div className="img-wrap"><img src={p1}/></div>
					<div className='btns'>
						<Link to="/beefi-detail"><div className='btn-item btn-light'>learn more</div></Link>
						<a href="https://shop.beemobile.xyz/products/beefi-x3"><div className='btn-item btn-normal'>buy now</div></a>
					</div>
					<div className='hint' style={{opacity:0}}></div>
				</div>
				<div className="product-item">
					<div className='light-text'>resolution: 320*320</div>
					<div className='main-text'>BEEWACTH W13</div>
					<div className='side-text'>aluminum alloy</div>
					<div className="img-wrap"><img src={p2}/></div>
					<div className='btns'>
						<Link to="/watch-detail"><div className='btn-item btn-light'>learn more</div></Link>
						<a href="https://shop.beemobile.xyz/products/beewacth-w13"><div className='btn-item btn-normal'>buy now</div></a>
					</div>
					<div className='hint'>Presale policy: The pre-sale product cycle is 30 days</div>
				</div>
			</div>
			<div className='p-beefi__service'>
				<div className='service-content'>
					<div className='service-item'>
						<div className='icon-service icon-s1'></div>
						<div className='title'>support</div>
						<div className='desc'>Provide you with the necessary technical support documents. If you cannot meet your requirements, you can contact us through Contcat Us </div>
					</div>
					<div className='service-item'>
						<div className='icon-service icon-s2'></div>
						<div className='title'>Bulletin</div>
						<div className='desc'>policy&operation related documents are available for you at any time</div>
					</div>
					<div className='service-item'>
						<div className='icon-service icon-s3'></div>
						<div className='title'>speed</div>
						<div className='desc'>policy&operation related documents are available for you at any time</div>
					</div>
				</div>
			</div>
			<CFooter/>
			
		</div>
    }
}
export default Index