import React, { Component } from 'react';
// 样式
import './index.less';

export default class extends Component {
    state={
        isMobile:/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)
    }
    resize=()=>{
        this.setState({
            isMobile:/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)
        })
    }
    componentDidMount(){
        window.addEventListener('resize',this.resize)
        document.body.style.overflow='hidden'
        document.body.style.height='100%'
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.resize)
        document.body.style.overflow='auto'
        document.body.style.height='auto'
    }
	// life cycle hooks
	render() {
		return (
			<div
				className={`c-dialog show ${this.state.isMobile?'dialog-mobile':'dialog-pc'}`}
			>
				{this.props.children}
			</div>
		);
	}
}