import React, { Component } from 'react'
import PropTypes from 'prop-types';
// 样式
import './index.less';

export default class extends Component {
	// props检查
	static propTypes = {
		type: PropTypes.oneOf([ 'text','password','number','digit' ]).isRequired,// 输入框类型
		value:PropTypes.oneOfType([ PropTypes.string,PropTypes.number]),// value
		placeholder:PropTypes.string,// placeholder
		disabled: PropTypes.bool,// disabled
		onInput: PropTypes.func,
		onBlur: PropTypes.func,
		onFocus: PropTypes.func,
		focus:PropTypes.bool,
		float:PropTypes.number,// 浮点数保留位数
		className:PropTypes.string,
		maxLength:PropTypes.number,
		error:PropTypes.bool
	};
	// props默认值
	static defaultProps = {
		type: 'text',
		value:'',
		placeholder:'',
		disabled: false,
		onInput: () => {},
		onBlur:()=>{},
		onFocus:()=>{},
		focus:false,
		float:2,
		className:'base',
		error:false
	};
	ref = React.createRef();
	// methods
	handleChange=(e)=>{
		if(this.props.disabled){
			return
		}
		let v=e.target.value&&e.target.value.trim()||''
		if(this.props.maxLength){
			v=v.slice(0,this.props.maxLength)
		}
		// 正整数
		if(this.props.type==='number'){
			v=v.replace(/[^0-9]/gi,'')
		}
		// 大于0的小数
		if(this.props.type==='digit'){
			v=v.replace(/[^0-9.]/gi,'')
			if(v.startsWith('.')){
				v='0'+v
			}
			const [ int, float ] = v.split('.') || [];
			if (float && Array.from(float).length > this.props.float) {
				v = `${int}.${float.substr(0, this.props.float)}`;
			}
		}
		this.props.onInput(v)
	}
	// 获取焦点
	handleFocus=()=>{
		this.props.onFocus()
	}
	// 失去焦点
	handleBlur=(e)=>{
		this.props.onBlur(e.target.value)
	}
	// life cycle hooks
	componentDidMount(){
		if(this.props.focus){
			setTimeout(()=>{
				this.ref.current.focus()
			})
		}
	}
	render() {
		const customCls=this.props.className.split(' ').map(item=>`input-${item}`).join(' ')
		const inputCls=`input ${this.props.disabled?'input-disabled':''} ${customCls} ${this.props.error?'input-error':''}`
		return (
			<input
				ref={this.ref}
				type={this.props.type==='password'?'password':this.props.type==='number'?'tel':this.props.type==='digit'?'number':'text'}
				value={this.props.value}
				placeholder={this.props.placeholder}
				maxLength={this.props.maxLength}
				className={inputCls}
				onChange={this.handleChange}
				onFocus={this.handleFocus}
				onBlur={this.handleBlur}
			/>
		);
	}
}


