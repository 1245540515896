import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import { Link,withRouter } from 'react-router-dom'

// 样式
import './index.less'

// 图片
import logo from '../../static/logo.png'

const mapStateToProps = () => {
	return {}
}
const mapDispatchToProps = {
}
@connect(mapStateToProps,mapDispatchToProps)
class Nav extends Component {
	// props检查
	static propTypes = {
		index: PropTypes.number
	};
	state={
		showLink:false
	}
	// life cycle hooks
	componentDidMount(){
	}
	componentWillUnmount(){
    }
	render() {
		return (
			<div className="c-nav">
				<div className="nav-content">
					<div className="nav-content-wrap">
						<div className="nav-content-main">
							<Link to="/" className="c-nav-logo">
								<img src={logo} width="39"/>
							</Link>
							<div className="c-nav-links">
								<Link to="/" className="c-nav-links__item">
									<div className={`text ${this.props.index===0?'active':''}`}>HOME</div>
								</Link>
								<Link to="/beefi" className="c-nav-links__item">
									<div className={`text ${this.props.index===1?'active':''}`}>BEEFI</div>
								</Link>
								<Link to="/beewatch" className="c-nav-links__item">
									<div className={`text ${this.props.index===2?'active':''}`}>BEEWATCH</div>
								</Link>
								<Link to="/support" className="c-nav-links__item">
									<div className={`text ${this.props.index===3?'active':''}`}>SUPPORT</div>
								</Link>
								<Link to="/contact" className="c-nav-links__item">
									<div className={`text ${this.props.index===4?'active':''}`}>CONTACT</div>
								</Link>
							</div>
						</div>
						<div className="c-nav-btns">
							{/* <div className="c-nav-btns__item">
								<div className="icon-search"></div>
							</div> */}
							<a  className="c-nav-btns__item" href="https://shop.beemobile.xyz/account/login?return_url=%2Faccount">
								<div className="icon-user"></div>
							</a>
						</div>
					</div>
				</div>
				<div className="nav-mobile">
					{!this.state.showLink&&<div onClick={()=>{this.setState({showLink:true})}} className='icon-links-fold'></div>}
					{this.state.showLink&&<div onClick={()=>{this.setState({showLink:false})}} className='icon-close'></div>}
					<div>
						<img src={logo} width="39"/>
					</div>
					<a href="https://shop.beemobile.xyz/account/login?return_url=%2Faccount">
						<div className="icon-user"></div>
					</a>
				</div>
				{this.state.showLink&&<div className="nav-mobile-content">
					<Link to="/" className="nav-item">
						<div>HOME</div>
					</Link>
					<Link to="/beefi" className="nav-item">
						<div>BEEFI</div>
					</Link>
					<Link to="/beewatch" className="nav-item">
						<div>BEEWATCH</div>
					</Link>
					<Link to="/support" className="nav-item">
						<div>SUPPORT</div>
					</Link>
					<Link to="/contact" className="nav-item">
						<div>CONTACT US</div>
					</Link>
				</div>}
			</div>
		);
	}
}

export default withRouter(Nav)
