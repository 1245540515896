import React, { Component } from 'react';
import PropTypes from 'prop-types';
// 样式
import './index.less';

export default class extends Component {
	// props检查
	static propTypes = {
		type: PropTypes.oneOf([ 'primary','warning','info' ]).isRequired,
		disabled: PropTypes.bool,
		onClick: PropTypes.func,
		size:PropTypes.oneOf([ 'large','middle','small' ])
	};
	// props默认值
	static defaultProps = {
		type: 'primary',
		disabled: false,
		onClick: () => {},
		size:'large'
	};
	// methods
	handleClick=(e)=>{
		if(this.props.disabled)return
		this.props.onClick(e)
	}
	// life cycle hooks
	render() {
		const btnCls=`btn ${`btn-${this.props.type}`} ${this.props.disabled?'btn-disabled':''} ${`btn-${this.props.size}`}`
		return (
			<div
				className={btnCls}
				onClick={this.handleClick}
			>
				{this.props.children}
			</div>
		);
	}
}


