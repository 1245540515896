import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { CNav,CFooter } from '@components'
import {CToast} from '@components'
// 样式
import './index.less'

const mapStateToProps = () => {
    return {}
}
const mapDispatchToProps = {}
@connect(mapStateToProps, mapDispatchToProps)
class Index extends Component {
    state = {
        name:'',
        email:'',
        phone:''
    }
    componentDidMount() {}

    click=()=>{
        const {name,email,phone} =this.state
        if(!name){
            CToast.error('Please input name !')
            return
        }
        if(!email){
            CToast.error('Please input email !')
            return
        }
        
        if(!phone){
            CToast.error('Please input phone !')
            return
        }
        if(email&&!/[\w.-]+@([\w-]+\.)+[\w-]+/.test(email)){
			CToast.error('email format invalid !')
			return;
		}
        CToast.info('Send succesful !')
    }
    handleInput=(event,type)=>{
       
        this.setState({
            [type]:event.target.value
        })
    }
    render() {
        return (
            <div className="p-contact">
                <CNav index={4}></CNav>

                <div className="p-contact-header">
                    <h3 className='animate__animated  animate__fadeIn animate__slower'>Web3.0</h3>
                    <div className='animate__animated  animate__fadeIn animate__slower'>THE FUTURE OF POSSIBLE</div>
                </div>

                <div className="p-contact-content animate__animated  animate__fadeIn animate__slower">
                    <div className="p-contact-content-title">Send a Request</div>
                    <div className="p-contact-content-text">If you have any questions, please contact us</div>
                    <div className="p-contact-content-input">
                        <input type="text" placeholder="Name" onChange={(event)=>{this.handleInput(event,"name")}}/>
                        <input type="text" placeholder="Email" onChange={(event)=>{this.handleInput(event,"email")}}/>
                        <input type="text" placeholder="Phone" onChange={(event)=>{this.handleInput(event,"phone")}}/>
                    </div>
                    <input type="text" placeholder="Comment" />
                    <div className="p-contact-content-btn">
                        <button onClick={()=>{this.click()}}>Send</button>
                    </div>
                    <div className="p-contact-content-title">Contact Us</div>
                    <div className="p-contact-content-text">
					If you have any question, please contact Customer Service, We will reply generally within 48 hours.
For general information, such as buying process, payment methods, delivery options or dispute process. If you need any inquiries with your recent orders, please contact tbdress Customer Service Center at customer@beemobile.xyz.

                    </div>
                    <div className="p-contact-content-email">
                        <li>
                            <span>Online Customer Support: </span>
                            <a href="https://shop.beemobile.xyz/pages/contact"><span>click here</span></a>
                        </li>
                        {/* <li>
                            <span>Online Customer Support:</span>
							<Link to="/support"><span >click here</span></Link>
                        </li> */}
                        <li>
                            <span>Email:</span>
                            <span className='email-info'>customer@beemobile.xyz</span>
                        </li>
                        {/* <li>
                            <span>Email:</span>
                            <span >customer@beemobile.xyz</span>
                        </li> */}
                    </div>
                </div>
                <CFooter/>
            </div>
        )
    }
}
export default Index
