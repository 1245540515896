import React, {Component} from 'react'
import {connect} from 'react-redux'
// import { Link } from 'react-router-dom'
import {CNav,CFooter} from '@components'
// 样式
import './index.less'
import bannerImg from '../../static/beefi-detail-banner-img.png'
import img1 from '../../static/beefi-detail-img1.png'
import img2 from '../../static/beefi-detail-img2.png'
import img3 from '../../static/beefi-detail-img3.png'
import img4 from '../../static/beefi-detail-img4.png'
import img5 from '../../static/beefi-detail-img5.png'
import img6 from '../../static/beefi-detail-img6.png'
import img7 from '../../static/beefi-detail-img7.png'
import img8 from '../../static/beefi-detail-img8.png'
import img9 from '../../static/beefi-detail-img9.png'
import img10 from '../../static/beefi-detail-img10.png'
import img11 from '../../static/beefi-detail-img11.png'
import img12 from '../../static/beefi-detail-img12.png'
import img13 from '../../static/beefi-detail-img13.png'
import img14 from '../../static/beefi-detail-img14.png'
import img15 from '../../static/beefi-detail-img15.png'
import img16 from '../../static/beefi-detail-img16.png'
import img17 from '../../static/beefi-detail-img17.png'
import img18 from '../../static/beefi-detail-img18.png'
import img19 from '../../static/beefi-detail-img19.png'
import img20 from '../../static/beefi-detail-img20.png'
import img21 from '../../static/beefi-detail-img21.png'
import img22 from '../../static/beefi-detail-img22.png'
import img23 from '../../static/beefi-detail-img23.png'
import download from '../../static/beefi-download.png'

const mapStateToProps = () => {
	return {}
};
const mapDispatchToProps = {

};
@connect(mapStateToProps,mapDispatchToProps)
class BeefiDetail extends Component{
    state={
        
    }
	componentDidMount(){

	}
	componentWillUnmount(){
	}
    render(){
        return <div className="p-beefi-detail">
			<CNav index={1}></CNav>
			<div className="p-beefi-detail__banner">
				<div className="p-beefi-detail__banner-item banner-img-wrap">
					<img className="banner-img" src={bannerImg}/>
				</div>
				<div className="p-beefi-detail__banner-item banner-text-wrap">
					<div className='banner-text'>
						<div className='banner-title'>BeeFi X3</div>
						<div className='banner-side-title'>Quick Start Guide</div>
					</div>
				</div>
			</div>
			<div className='beefi-detail-content'>
				<div className='title'>Nomenclature</div>
				<div className='beefi-detail-content-section1'>
					<div className='beefi-up'>
						<div className='model-img'>
							<img src={img1}/>
							<div className='circle circle1'></div>
							<div className='circle circle2'></div>
							<div className='circle circle3'></div>
							<div className='line line1'></div>
							<div className='line line2'></div>
							<div className='line line3'></div>
							<div className='text-reverse text1 desc text-blue'>Wi-Fi Indicator</div>
							<div className='text-reverse text2 desc text-red'>Low battery</div>
							<div className='text text3 desc text-blue'>System Indicator</div>
							<div className='text text4 desc text-red'>High battery</div>
							<div className='text text5 desc text-green'>ChargingTurn off when fully charged</div>
							<div className='text text6 desc text-blue'>LTE Indicator </div>
							<div className='text text7 desc text-red'>Middle battery</div>
						</div>
					</div>
					<div className='beefi-hickey'>
						<div className='model-img-front'>
							<img src={img2}/>
							<div className='circle circle1'></div>
							<div className='circle circle2'></div>
							<div className='line line1'></div>
							<div className='line line2'></div>
							<div className='text text1 desc'>Power Source Port</div>
							<div className='text text2 desc'>Ethernet Port</div>
						</div>
						<div className='model-img-back'>
							<img src={img3}/>
							<div className='circle circle1'></div>
							<div className='circle circle2'></div>
							<div className='circle circle3'></div>
							<div className='line line1'></div>
							<div className='line line2'></div>
							<div className='line line3'></div>
							<div className='text text1 desc'>Power Switch <br/>
(ON/OFF)</div>
							<div className='text text2 desc'>Device Reset</div>
							<div className='text text3 desc'>SIM Port</div>
						</div>
					</div>
				</div>
				<div className='title'>Setting up the Device</div>
				<div className='beefi-detail-content-section2'>
					<div className='setup-left'>
						<div className='setup-left-row'>
							<img width="197" src={img4}/>
							<div className='desc'>Make sure the internal battery is 
charged, if not, connect the device to 
a power source.</div>
						</div>
						<div className='setup-left-row'>
							<img width="198" src={img5}/>
							<div className='desc'>Insert your sim card to 
connect to the internet.</div>
						</div>
						<div className='setup-left-row'>
							<img width="198" src={img5}/>
							<div className='desc'>Turn ON the Device.
Long press 3s to turn on/off and short 
press to display the power indicator.</div>
						</div>
					</div>
					<div className='setup-right'>
						<img width="206" src={img6}/>
						<div className='desc'>
						Wait for 6 minutes for the 3.BeeFi X3
firmware to finish initialization. 
Confirm that the following LEDs are On, 
system , wifi and internet.
						</div>
					</div>
				</div>
				<div className='title'>Connection via Wi-Fi</div>
				<div className='beefi-detail-content-section3'>
					<div className='connect-content'>
						<div className='connect-column column-all'>
							<img width="206" src={img6}/>
							<div className='desc'>Wait for 6 minutes for the 3.BeeFi X3
	firmware to finish initialization. 
	Confirm that the following LEDs are On, 
	system , wifi and internet.</div>
						</div>
						<div className='connect-column column-half'>
							<img width="116" src={img7}/>
							<div className='desc'>Connect your phone or PC via WiFi to 
	the device via SSID.
	（SSID name ： beemobile.xyz）</div>
						</div>
						<div className='connect-column column-half'>
							<img width="179" src={img8}/>
							<div className='desc'>Authenticate your device via the BeeFi 
	captive portal. (this step is only required if 
	Bee MiFi business is enabled).</div>
						</div>
					</div>
					<div className='remark'>Note: You can access the captive portal using the URL wicryptlogin.net
or 192.168.4.4 if the captive portal is not displayed automatically.</div>
				</div>
				<div className='title'>Connection via Ethernet Cable</div>
				<div className='beefi-detail-content-section4'>
					<div className='remark'>Kindly follow the steps in setting up your device with a SIM 
card above in page 5</div>
					<div className='cable-content'>
						<div className='cable-content-left'>
							<div className='cable-content-left-row'>
								<img width="197" src={img4}/>
								<div className='desc'>Ethernet port defaults to WAN port，
 Connect an ethernet cable from the 
Router to WAN port </div>
							</div>
							<div className='cable-content-left-row row2'>
								<img width="179" src={img8}/>
								<div className='desc'>Authenticate your device via the 
BeeFi captive portal. 
(this step is only required if BeeFi 
business is enabled).</div>
							</div>
						</div>
						<div className='cable-content-right'>
							<img src={img9}/>
							<div className='desc'>If need to switch to LAN port, 
Click the quick setup tap and switch 
from WAN to LAN</div>
						</div>
					</div>
				</div>
				<div className='title'>How to Connect to your BeeFi Device</div>
				<div className='beefi-detail-content-section5'>
					<div className='device-content'>
						<div className="column">
							<img width="198" src={img5}/>
							<div className="desc">Insert a SIM card or connect your 
Wicrypt device via the Ethernet 
cable to the internet</div>
						</div>
						<div className="column column2">
							<img width="144" src={img7}/>
							<div className="desc">Make sure you are connected to the 
device network 
(beemobile.xyz) 
via WiFi or Ethernet cable</div>
						</div>
					</div>
				</div>
				<div className='title'>How to Register a Device</div>
				<div className='beefi-detail-content-section6'>
					<div className='device-content'>
						<div className="column">
							<div className="column-row row1">
								<img width="198" src={img5}/>
								<div className='desc'>Insert a SIM card or connect your 
								BeeFi device via the Ethernet cable 
								to the internet</div>
							</div>
							<div className="column-row row2">
								<img width="200" src={img10}/>
								<div className='desc'>Connect your phone or PC via WiFi to 
the device via SSID.</div>
							</div>
						</div>
						<div className="column">
							<div className="column-row row3">
								<img src={img11}/>
								<div className='desc'>Open your browser and type in the 
IP 192.168.4.4 or the URL wicryptlogin.net</div>
							</div>
							<div className="column-row row4">
								<img src={img12}/>
								<div>
									<div className='desc'>On the device portal, Click “Register 
									Hotspot Hub” .  Enter you email and 
									pin, Click ‘Register’ </div>
									<div className='remark'>NB: This pin is created during Wicrypt APP 
registration.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='title'>How to Setup Device Password</div>
				<div className='beefi-detail-content-section7'>
					<div className='column-3'>
						<img src={img13}/>
						<div className='desc desc-relative'>On the device portal, Click ‘Quick Setup</div>
					</div>
					<div className='column-3'>
						<img src={img15}/>
						<div className='desc'>Under ‘Device Password’, 
input desired password</div>
					</div>
					<div className='column-3'>
						<img src={img14}/>
						<div className='desc'>Click ‘Save Changes’</div>
					</div>
				</div>
				<div className='title'>How to Change Device SSID Name</div>
				<div className='beefi-detail-content-section7'>
					<div className='column-3'>
						<img src={img13}/>
						<div className='desc desc-relative'>On the device portal, Click ‘Quick Setup</div>
					</div>
					<div className='column-3'>
						<img src={img16}/>
						<div className='desc'>Enter desired WiFi name</div>
					</div>
					<div className='column-3'>
						<img src={img14}/>
						<div className='desc'>Click ‘Save Changes’<br/><br/>
						NB: The Device would be disconnected 
						and you will be required to reconnect via the 
						new Wi-Fi name</div>
					</div>
				</div>
				<div className='title'>How to Switch from Personal to Business Mode</div>
				<div className='beefi-detail-content-section6'>
					<div className='device-content'>
						<div className="column">
							<div className="column-row row1">
								<img width="198" src={img5}/>
								<div className='desc'>Insert a SIM card or connect your 
BeeFi device via the Ethernet cable 
to the internet</div>
							</div>
							<div className="column-row row2">
								<img width="200" src={img10}/>
								<div className='desc'>Connect to the device network 
(beemobile.xyz)</div>
							</div>
						</div>
						<div className="column">
							<div className="column-row row3">
								<img src={img11}/>
								<div className='desc'>Open your browser and type in the 
IP 192.168.4.4 or the URL wicryptlogin.net</div>
							</div>
							<div className="column-row row4">
								<img src={img8}/>
								<div>
									<div className='desc'>Input “admin” as the device password 
to login to the portal</div>
									<div className='remark'>NB: if you had changed the device password. 
Input the password you used.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='beefi-detail-content-section7' style={{paddingTop:'10px',paddingBottom:'10px'}}>
					<div className='column-3'>
						<img src={img17}/>
						<div className='desc desc-relative'>Click on ‘Business’</div>
					</div>
					<div className='column-3'>
						<img src={img18}/>
						<div className='desc'>Select Enable</div>
						<div className="remark">NB: if you have already set the password 
under the personal mode, kindly disable 
the password before enabling 
business mode</div>
					</div>
					<div className='column-3'>
						<img src={img19}/>
						<div className='desc'>Click on ‘Save Changes’</div>
						<div className="remark">NB: This will restart the device automatically and 
you will be required to login into the device 
network using the Wicrypt app device login code 
or a voucher</div>
					</div>
				</div>
				<div className="remark center-remark jx">Note: Device mode by default is personal mode</div>
				<div className='title'>How to Switch from Buisness to Personal Mode</div>
				<div className='beefi-detail-content-section6'>
					<div className='device-content'>
						<div className="column">
							<div className="column-row row1">
								<img width="198" src={img5}/>
								<div className='desc'>Insert a SIM card or connect your 
BeeFi device via the Ethernet 
cable to the internet</div>
							</div>
							<div className="column-row row2">
								<img width="200" src={img10}/>
								<div>
									<div className='desc'>Connect to the device or Mi-Fi network 
									(beemobile.xyz)</div>
									<div className='remark' style={{marginTop:'20px'}}>NB: if the SSID had been changed earlier. 
										Connect to the SSID used</div>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="column-row row3">
								<img src={img11}/>
								<div className='desc'>Open your browser and type in the 
IP 192.168.4.4 or the URL wicryptlogin.net</div>
							</div>
							<div className="column-row row4">
								<img src={img8}/>
								<div>
									<div className='desc'>Input “admin” as your default password to 
login to the portal or the password you 
might have changed it to</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='beefi-detail-content-section7' style={{paddingTop:'10px',paddingBottom:'10px'}}>
					<div className='column-3'>
						<img src={img17}/>
						<div className='desc desc-relative'>Click on ‘Business’</div>
					</div>
					<div className='column-3'>
						<img src={img18}/>
						<div className='desc'>Select Disable</div>
						<div className="remark">NB: if you have already set the password under 
the business mode, kindly disable the password 
before enabling personal mode</div>
					</div>
					<div className='column-3'>
						<img src={img19}/>
						<div className='desc'>Click on ‘Save Changes’</div>
						<div className="remark">NB: This will restart the device automatically and 
you will be required to login into the device 
network using the Wicrypt app device login code 
or a voucher.</div>
					</div>
					<div className='column-3 relative-up'>
						<img src={img6}/>
						<div className='desc'>Device will need to be restarted. 

Ensure that the Wi-Fi light is on and blinking. 
it can take up to 6 minutes for the 
devices to be ready</div>
					</div>
				</div>
				<div className='title'>How to Setup/Change your Device name (SSID) to your Business Name</div>
				<div className='beefi-detail-content-section7 section-img-bottom' style={{paddingTop:'10px',paddingBottom:'10px'}}>
					<div className='column-3'>
						<div className='img-wrap'><img src={img11}/></div>
						<div className='desc'>Open your browser and type in the 
IP 192.168.4.4 or the URL wicryptlogin.net</div>
					</div>
					<div className='column-3'>
						<div className='img-wrap'><img className='img-relative' src={img8}/></div>
						<div className='desc'>Input “admin” as your default password 
to login to the portal or the password 
you might have changed it to</div>
					</div>
					<div className='column-3'>
						<div className='img-wrap'><img src={img17}/></div>
						<div className='desc'>Click on ‘Quick Setup’ to change the 
name of your Wi-Fi to your business name.</div>
					</div>
				</div>
				<div className="remark center-remark jx2">Download the Wicrypt mobile app and register. </div>
				<div className='beefi-detail-content-section8'>
					<div className='device-content'>
						<div className="column">
							<img src={img20}/>
							<div className="desc">On the app, Click on “My Devices” </div>
						</div>
						<div className="column">
							<img src={img21}/>
							<div className="desc">Select “Update Location” ，
declare the current position</div>
						</div>
					</div>
				</div>
				<div className='beefi-detail-content-section8'>
					<div className='device-content'>
						<div className="column">
							<img src={img22}/>
							<div>
								<div className="desc">Select “More info” ， click ”Edit“，Input 
								your new Wi-Fi name as your Device 
								and SSID name.  </div>
								<div className='remark'>NB: Ensure that the device and SSID name 
corresponds to your Wi-Fi business name on 
the portal </div>
							</div>
						</div>
						<div className="column">
							<img src={img23}/>
							<div className="desc">Set your billing rate (the amount you want 
users to pay when they connect to 
your network) 

Click on ‘Save’ 
</div>
						</div>
					</div>
				</div>
			</div>
			<div className='p-beefi-detail__download'>
				<div className='download-text'>
					<div className="text-f">
						<div className='download-title'>APP download and login</div>
						<div className='download-icons'>
							<div className='download-icons-d1'></div>
							<div className='download-icons-d2'></div>
							<div className='download-icons-d3'></div>
							<div className='download-icons-d4'></div>
						</div>
						<div className='download-hint'>{`APP download method "Wicrypt":`}</div>
						1.Download the Wicrypt mobile app and register. <br/> <br/>
						{`2.On the app, Click on 'Profile'`} <br/> <br/>
						{`3.Select "My Devices" `}<br/> <br/>
						{`4.Click on 'Add Devices'. `}
					</div>
					<div className="text-s">
						5.Input your new Wi-Fi name as your Device and SSID name. NB: Ensure that the device and SSID name corresponds to your Wi-Fi business name on the portal<br/><br/>
						6.Set your billing rate (the amount you want users to pay when they connect to your network)<br/><br/>
						{`7.Click on 'Save'`}
					</div>
					<div className='download-btns'>
						<a href="https://play.google.com/store/apps/details?id=com.wicrypt.app&pli=1" target="_blank" className='download-btns-item' rel="noreferrer">
							<div className='icon-andriod'></div>
							<div>Android</div>
						</a>
						<a href="https://apps.apple.com/ng/app/wicrypt/id1451821605" target="_blank" className='download-btns-item' rel="noreferrer">
							<div className='icon-ios'></div>
							<div>iPhone</div>
						</a>
					</div>
				</div>
				<img src={download} className='download-img'/>
			</div>
			<CFooter/>
		</div>
    }
}
export default BeefiDetail