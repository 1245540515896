import React, { Component } from 'react'
import CInput from '../c-input'
import  './index.less';

export default class extends Component {
	static defaultProps = {
		min:1,
		max:100000,
		num: 1,
		onChange: () => {}
	};

	handleMinus = () => {
		if (this.props.num <= parseInt(this.props.min)) {
			return
		}
		this.props.onChange(this.props.num - 1);
	};

	handlePlus = () => {
		if (this.props.num >= parseInt(this.props.max)) {
			return
		}
		this.props.onChange(this.props.num + 1);
	};

	handleInput = (v) => {
		if(!v){
			this.props.onChange(v)
			return
		}
		this.props.onChange(Math.floor(v.replace(/-/gi,'')));
	};
	handleBlur=(v)=>{
		if (parseInt(v) < parseInt(this.props.min)) {
			this.props.onChange(parseInt(this.props.min));
		}
		if (parseInt(v) > parseInt(this.props.max)) {
			this.props.onChange(parseInt(this.props.max));
		}
	}

	render() {
		const { num } = this.props;
		const isMinusDisabled = num <= this.props.min;
		const isAddDisabled = num>=this.props.max
		return (
			<div className='c-input-number'>
				<div onClick={this.handleMinus} className={`input-button ${isMinusDisabled ? 'button-disabled' : '' }`}
				>
					-
				</div>
				<div className='input-area'>
					<CInput className='num' type='number' onInput={this.handleInput} onBlur={this.handleBlur} value={num} />
				</div>
				<div onClick={this.handlePlus} className={`input-button ${isAddDisabled ? 'button-disabled' : '' }`}>
					+
				</div>
			</div>
		);
	}
}
