import React, { Component } from 'react'

// 样式
import './toast.less';

export default class ToastBox extends Component {
    transitionTime = 300
    state = { notices: [] }

    getNoticeKey=()=> {
        const { notices } = this.state
        return `notice-${new Date().getTime()}-${notices.length}`
    }

    addNotice=(notice)=> {
        const { notices } = this.state
        notice.key = this.getNoticeKey()

        notices[0] = notice;
        
        this.setState({ notices })
        if (notice.duration > 0) {
            setTimeout(() => {
                this.removeNotice(notice.key)
            }, notice.duration)
        }
        return () => { this.removeNotice(notice.key) }
    }

    removeNotice(key) {
        const { notices } = this.state
        this.setState({
            notices: notices.filter((notice) => {
                if (notice.key === key) {
                    if (notice.onClose) setTimeout(notice.onClose, this.transitionTime)
                    return false
                }
                return true
            })
        })
    }

    render() {
        const { notices } = this.state
        const icons = {
            info: 'toast_info',
            success: 'toast_success',
            error: 'toast_error',
            loading: 'toast_loading'
        }
        return (
            <div className="c-toast">
                {
                    notices.map(notice => (
                        <div className={`c-toast__item show  ${icons[notice.type]}`} key={notice.key}>
                            {notice.content}
                        </div>
                    ))
                }
            </div>
        )
    }
}