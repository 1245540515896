import React, { Component,createRef } from 'react';
import PropTypes from 'prop-types';
// 样式
import './index.less';

export default class extends Component {
	// props检查
	static propTypes = {
		imgUrl: PropTypes.string
	};
	ref=createRef()
	state={
        imgStyle:{
			width:'100%'
		}
    }
	setSize=()=>{
        this.setState({
            imgStyle:this.ref.current.naturalWidth/this.ref.current.naturalHeight>this.ref.current.parentNode.scrollWidth/this.ref.current.parentNode.scrollHeight?{
                height:'100%'
            }:{
                width:'100%'
            }
        })
    }
	// methods
	// life cycle hooks
	render() {
		return (
			<div className="c-img-wrap">
				<img onLoad={this.setSize} ref={this.ref} style={this.state.imgStyle} src={this.props.imgUrl} className="c-img"/>
			</div>
		);
	}
}


