import React from 'react'
import { render } from 'react-dom'
import App from './app'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import 'animate.css'
import '@src/styles/index.less'
import configStore from '../store'

const { store, persistor } = configStore();

render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
		</PersistGate>
    </Provider>,
    document.getElementById('root')
);