import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import {CNav,CFooter} from '@components'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);
// 样式
import './index.less'

import beefi from '../../static/img-beefi.png'
import beewatch from '../../static/img-beewatch.png'

const mapStateToProps = () => {
	return {}
};
const mapDispatchToProps = {

};
@connect(mapStateToProps,mapDispatchToProps)
class Index extends Component{
    state={
        
    }
	componentDidMount(){
		
	}
    render(){
        return <div className="p-support">
			<CNav index={3}></CNav>
			<div className='p-support__banner'>
				<div className='title'>Bee Support</div>
				<div className='slogan'>Start your service journey</div>
				<div className='phones'>
					<div className='phone-item phone1'>
						<div className='main'>bEEFI X3</div>
						<Link to="/beefi-detail"><div className='side'>User Manuals</div></Link>
						<img src={beefi} className="img"/>
					</div>
					<div className='phone-item phone2'>
						<div className='main'>BEEWACTH W13</div>
						<Link to="/watch-detail"><div className='side'>User Manuals</div></Link>
						<img src={beewatch} className="img"/>
					</div>
				</div>
			</div>
			{/* <div className='p-support__service'>
				<div className='service-content'>
					<div className='service-item'>
						<div className='icon-service icon-s1'></div>
						<div className='title'>support</div>
						<div className='desc'>Provide you with the necessary technical support documents. If you cannot meet your requirements, you can contact us through Contcat Us </div>
					</div>
					<div className='service-item'>
						<div className='icon-service icon-s2'></div>
						<div className='title'>Bulletin</div>
						<div className='desc'>policy&operation related documents are available for you at any time</div>
					</div>
					<div className='service-item'>
						<div className='icon-service icon-s3'></div>
						<div className='title'>speed</div>
						<div className='desc'>policy&operation related documents are available for you at any time</div>
					</div>
				</div>
			</div> */}
			<CFooter/>
		</div>
    }
}
export default Index