import { SET_USER } from '../constants/user';

const INITIAL_STATE={
	loginStatus:false,
	email:''
}

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_USER:
			return {
				loginStatus:action.payload.loginStatus,
				email:action.payload.email
			};
		default:
			return state;
	}
}
