import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import * as imageArray from './image.js';
gsap.registerPlugin(ScrollTrigger);
// 样式
import './index.less'

const animateCss={
	large:{
		line1Styles:{
			left: '280px',
			top: '160px',
			width:'1px',
			height:'192px',
		},
		line2Styles:{
			left: '215px',
			top: '410px',
			width:'1px',
			height:'198px',
		},
		line3Styles:{
			width:'1px',
			height:'180px',
			left:'526px',
			top: '430px',
		},
		line4Styles:{
			left: '240px',
			top: '210px',
			width:'1px',
			height:'187px',
		},
		line5Styles:{
			left: '526px',
			top: '420px',
			width:'1px',
			height:'180px',
		},
		text1Styles:{
			left: '290px',
			top:'160px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text2Styles:{
			left: '225px',
			top: '540px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text3Styles:{
			left:'540px',
			top:'580px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text4Styles:{
			left: '250px',
			top: '210px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text5Styles:{
			left: '540px',
			top: '564px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		sloganStyles:{
			fontSize:'58px',
			lineHeight:'71px',
			top:'70px',
		}
	},
	medium:{
		line1Styles:{
			left: '174px',
			top: '100px',
			width:'1px',
			height:'122px',
		},
		line2Styles:{
			left: '132px',
			top: '260px',
			width:'1px',
			height:'122px',
		},
		line3Styles:{
			left: '328px',
			top: '260px',
			width:'1px',
			height:'122px',
		},
		line4Styles:{
			left: '148px',
			top: '120px',
			width:'1px',
			height:'127px',
		},
		line5Styles:{
			left: '326px',
			top: '260px',
			width:'1px',
			height:'120px',
		},
		text1Styles:{
			left: '186px',
			top:'100px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text2Styles:{
			left: '146px',
			top: '340px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text3Styles:{
			left: '338px',
			top:'360px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text4Styles:{
			left: '158px',
			top: '120px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text5Styles:{
			left: '336px',
			top: '350px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		sloganStyles:{
			fontSize:'42px',
			lineHeight:'50px',
			top:'0px',
		}
	},
	small:{
		line1Styles:{
			left: '132px',
			top: '70px',
			width:'1px',
			height:'102px',
		},
		line2Styles:{
			left: '102px',
			top: '190px',
			width:'1px',
			height:'102px',
		},
		line3Styles:{
			left: '250px',
			top: '190px',
			width:'1px',
			height:'102px',
		},
		line4Styles:{
			left: '114px',
			top: '80px',
			width:'1px',
			height:'107px',
		},
		line5Styles:{
			left: '250px',
			top: '216px',
			width:'1px',
			height:'100px',
		},
		text1Styles:{
			left: '142px',
			top: '70px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text2Styles:{
			left: '112px',
			top: '264px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text3Styles:{
			left: '260px',
			top: '280px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text4Styles:{
			left: '124px',
			top: '80px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		text5Styles:{
			left: '260px',
			top: '300px',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '17px',
		},
		sloganStyles:{
			fontSize:'24px',
			lineHeight:'44px',
			top:'-30px',
		}
	}
}
const mapStateToProps = () => {
	return {}
};
const mapDispatchToProps = {

};
@connect(mapStateToProps,mapDispatchToProps)
class VideoAnimation extends Component{
	// props检查
	static propTypes = {
		fps:PropTypes.number,
		width:PropTypes.number,
		height:PropTypes.number,
		imgWidth:PropTypes.number,
		imgHeight:PropTypes.number,
	};
	static defaultProps = {
		fps:30,// 控制动画快慢
		preFps:0,// 动画开始前静止时间控制
		afterFps:0,// 动画结束静止时间控制
	}
	refWrap = React.createRef();
	refCanvasWrap = React.createRef();
	refCanvas = React.createRef();
    state={
        count:Object.keys(imageArray).length,// 总帧数
		images:{},// 图片对象
    }
	// 预加载图片
	preloadImg = () => {
		const loadedImgs={}
		for (let i = 0; i < this.state.count ; i++) {
			const img = new Image();
			img.src = imageArray[`p${i}`];
			loadedImgs['img'+i]=img
		}
		this.setState({
			images:loadedImgs
		})
	}
	// 更新绘制
	updateImg = index => {
		const canvasWrap =this.refCanvasWrap.current
		const canvas = this.refCanvas.current
		const ctx = canvas.getContext("2d")
		canvas.width=this.props.width
		canvas.height=this.props.height
		canvasWrap.style.width=this.props.width+'px'
		canvasWrap.style.height=this.props.height+'px'
		canvasWrap.style.transform=`translate(${(window.innerWidth-this.props.width)/2}px,${(window.innerHeight-this.props.height)/2}px)`
		ctx.clearRect(0, 0,this.props.imgWidth, this.props.imgHeight,0,0, this.props.width, this.props.height)		
		const img=this.state.images['img'+index]
		ctx.drawImage(img, 0, 0,this.props.imgWidth, this.props.imgHeight,0,0, this.props.width, this.props.height);
		img.onload=()=>{
			ctx.drawImage(img, 0, 0,this.props.imgWidth, this.props.imgHeight,0,0, this.props.width, this.props.height);
		}
	}

	// 页面滚动动画
	handleScroll=(e)=>{
		const wrapDom=this.refWrap.current
		const {top}=wrapDom.getBoundingClientRect() // 视频动画距离可视区顶部的近距离
		const wrapHeight=wrapDom.scrollHeight // 视频动画高度
		const windowHeight=window.innerHeight // 窗口高度
		if(top>-this.props.preFps){// 前置动画影响
			// 未到可视区，渲染第一张图
			!e&&requestAnimationFrame(() => this.updateImg(0))
			return
		}
		if(windowHeight-wrapHeight+this.props.afterFps>=top){// 前置动画影响
			// 滚动过可视区，渲染最后一张图
			!e&&requestAnimationFrame(() => this.updateImg(this.state.count-1))
			return
		}
		const maxScrollHeight=wrapHeight-windowHeight-this.props.preFps-this.props.afterFps
		const index=Math.min(this.state.count,Math.ceil(((Math.abs(top)-this.props.preFps)/maxScrollHeight)*this.state.count))
		requestAnimationFrame(() => this.updateImg(index-1))
	}
	handleResize=()=>{
		this.handleScroll()
	}

	componentDidMount(){
		this.preloadImg()
		this.handleScroll()
		window.addEventListener('scroll', this.handleScroll,false);
		window.addEventListener('resize', this.handleResize,false);
		// 前置动画
		gsap.timeline({
			scrollTrigger: {
				trigger: ".c-video-wrap",
				start: "top top",
				end: `+=${this.props.preFps}`,
				scrub: true,
			}
		})
		.from(".c-video .line1",  {scaleY:0,transformOrigin: "left bottom"})
		.from(".c-video .text1",{opacity:0})
		.from(".c-video .line2",  {scaleY:0,transformOrigin: "left top"})
		.from(".c-video .text2",{opacity:0})
		.from(".c-video .line3",  {scaleY:0,transformOrigin: "left top"})
		.from(".c-video .text3",{opacity:0})
		.to(".c-video .line1,.c-video .text1,.c-video .line2,.c-video .text2,.c-video .line3,.c-video .text3",{opacity:0})
		// 后置动画
		const afterAnimationStartPosition=-(this.props.fps*this.state.count+this.props.preFps)
		gsap.timeline({
			scrollTrigger: {
				trigger: ".c-video-wrap",
				start: `top ${afterAnimationStartPosition}`,
				end: `+=${this.props.afterFps}`,
				scrub: true,
			}
		})
		.to(".c-video .line4",  {scaleY:1,opacity:1,transformOrigin: "left bottom"})
		.to(".c-video .text4",  {opacity:1})
		.to(".c-video .line5",  {scaleY:1,opacity:1,transformOrigin: "left top"})
		.to(".c-video .text5",  {opacity:1})
	}
	componentWillUnmount(){
		window.removeEventListener('scroll', this.handleScroll,false);
		window.removeEventListener('resize', this.handleResize,false);
	}
    render(){
		const height=this.props.fps*this.state.count+window.innerHeight+this.props.preFps+this.props.afterFps
		const fillHeight=window.innerHeight-this.props.height
        return <div className='c-video'>
					<div className="c-video-wrap" style={{height:`${height}px`}} ref={this.refWrap}>
						<div className='canvas-wrap' ref={this.refCanvasWrap}>
							<div className='canvas-content'>
								<canvas ref={this.refCanvas} />
								<div className='line1' style={animateCss[this.props.size].line1Styles}></div>
								<div className='text1' style={animateCss[this.props.size].text1Styles}>Device Reset</div>
								<div className='line2' style={animateCss[this.props.size].line2Styles}></div>
								<div className='text2' style={animateCss[this.props.size].text2Styles}>Power Switch<br/>(ON/OFF)</div>
								<div className='line3' style={animateCss[this.props.size].line3Styles}></div>
								<div className='text3' style={animateCss[this.props.size].text3Styles}>SIM Port</div>
								<div className='line4' style={animateCss[this.props.size].line4Styles}></div>
								<div className='text4' style={animateCss[this.props.size].text4Styles}>Power Source Port</div>
								<div className='line5' style={animateCss[this.props.size].line5Styles}></div>
								<div className='text5' style={animateCss[this.props.size].text5Styles}>Ethernet Port</div>
								<div className='canvas-slogan' style={animateCss[this.props.size].sloganStyles}>Everything in its place</div>
							</div>
						</div>
					</div>
					<div style={{width:'100%',height:`${fillHeight}px`}}></div>
				</div>
    }
}
export default VideoAnimation