import React, { Component } from 'react'
import {connect} from 'react-redux';
import {CToast} from '@components'
// import { Link } from 'react-router-dom'
// 方法

// 样式
import './index.less';
// 图片
import logo from '../../static/logo.png'
const mapStateToProps = () => {
	return {}
};
@connect(mapStateToProps)
class Footer extends Component {
	state={
		email:'',
		alwaysShow:false,
		showTouch:false,
		showStatement:false,
	}
	// methods
	handleChange=(e)=>{
		this.setState({
			email:e.target.value
		})
	}
	handleSubscribe=()=>{
		const {email}=this.state
		if(!/[\w.-]+@([\w-]+\.)+[\w-]+/.test(email)){
			CToast.error('email format invalid !')
			return;
		}
		CToast.info('Subscribe succesful !')
	}
	handleResize=()=>{
		this.setState({
			alwaysShow:window.innerWidth>=768
		})
	}
	// life cycle hooks
	componentDidMount(){
		this.handleResize()
		window.addEventListener('resize',this.handleResize,false)
    }
    componentWillUnmount(){
		window.removeEventListener('resize',this.handleResize,false)
    }
	render() {
		return (
			<div className={`c-footer`}>
				<div className="footer-content">
					<div className='footer-links'>
						<div className='footer-links-group'>
							<div className={`footer-links-group-title ${this.state.showTouch?'title-unfold':''}`} onClick={()=>{this.setState({showTouch:!this.state.showTouch})}}>Get In Touch</div>
							{(this.state.showTouch||this.state.alwaysShow)&&<>
							<a href="https://shop.beemobile.xyz/pages/bulletin"><div className='footer-links-group-item'>Bulletin</div></a>
							<a href="https://shop.beemobile.xyz/pages/support"><div className='footer-links-group-item'>Support</div></a>
							<a href="https://shop.beemobile.xyz/pages/contact"><div className='footer-links-group-item'>Contact us</div></a>
							<a href="https://shop.beemobile.xyz/pages/about-us"><div className='footer-links-group-item'>About us</div></a>
							</>}
						</div>
						<div className='footer-links-group'>
							<div className={`footer-links-group-title ${this.state.showStatement?'title-unfold':''}`} onClick={()=>{this.setState({showStatement:!this.state.showStatement})}}>Statement</div>
							{(this.state.showStatement||this.state.alwaysShow)&&<>
							<a href="https://shop.beemobile.xyz/pages/privacy-policy"><div className='footer-links-group-item'>Privacy Policy</div></a>
							<a href="https://shop.beemobile.xyz/pages/refund-policy"><div className='footer-links-group-item'>Refund Policy</div></a>
							<a href="https://shop.beemobile.xyz/pages/shipping-policy"><div className='footer-links-group-item'>Shipping Policy</div></a>
							<a href="https://shop.beemobile.xyz/pages/terms-of-service"><div className='footer-links-group-item'>Terms Of Service</div></a>
							</>}
						</div>
						<div className='footer-copyright-mobile'>
							<div className='language'>hong kong / english</div>
						</div>
						<div className='footer-links-group'>
							<div className='footer-links-group-title nofold'>Subscribe</div>
							<div className='footer-links-group-item nobg'>Get the latest news from Bee</div>
							<div className='footer-links-group-item-mail'>
								<input value={this.state.email} onChange={this.handleChange} className='address' placeholder="Your email address"/>
								<div className='icon-email' onClick={this.handleSubscribe}></div>
							</div>
						</div>
					</div>
					<div className='footer-social'>
						<div className='nav-items'>
							<div className='nav-items-item'><img src={logo} width="43"/></div>
							<a href="https://shop.beemobile.xyz/collections/frontpage"><div className='nav-items-item'>Products</div></a>
							<a href="https://shop.beemobile.xyz/pages/bulletin"><div className='nav-items-item'>bulletin</div></a>
							<a href="https://shop.beemobile.xyz/pages/support"><div className='nav-items-item'>support</div></a>
							<a href="https://shop.beemobile.xyz/pages/contact"><div className='nav-items-item'>contact us</div></a>
						</div>
						<div className='third-links'>
							<a href="https://www.facebook.com/profile.php?id=100089578405118" target="_blank" title="Follow on Twitter" rel="noreferrer"><div className='third-links-item icon-facebook'></div></a>
							<a href="https://twitter.com/Beemobile2022" target="_blank" title="Follow on Twitter" rel="noreferrer"><div className='third-links-item icon-twitter'></div></a>
							<a href="https://www.instagram.com/beemobile2023/" target="_blank" title="Follow on Instagram" rel="noreferrer"><div className='third-links-item icon-instagram'></div></a>
							<a href="https://www.linkedin.com/" target="_blank" title="Follow on Linkedin" rel="noreferrer"><div className='third-links-item icon-linkedin'></div></a>
							<a href="https://www.pinterest.com/beemobile2022/" target="_blank" title="Follow on Pinterest" rel="noreferrer"><div className='third-links-item icon-pinterest'></div></a>
						</div>
					</div>
					<div className='footer-copyright'>
						<div>Copyright © beelabs all rights reserved.</div>
						<div className='language'>hong kong / english</div>
					</div>
					<div className='footer-social-mobile'>
						<div className='nav-items'>
							<a href="https://shop.beemobile.xyz/collections/frontpage"><div className='nav-items-item'>Products</div></a>
							<a href="https://shop.beemobile.xyz/pages/bulletin"><div className='nav-items-item'>bulletin</div></a>
							<a href="https://shop.beemobile.xyz/pages/support"><div className='nav-items-item'>support</div></a>
							<a href="https://shop.beemobile.xyz/pages/contact"><div className='nav-items-item'>contact us</div></a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Footer
