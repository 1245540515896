import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { CNav, CFooter } from '@components'

// 样式
import './index.less'

//图片
import Vwatch from '../../static/images/v-watch.png'
import VcamL from '../../static/images/v-camL.png'
// import VL from '../../static/images/v-l.png'
import Vbee from '../../static/images/v-bee.png'
import Vyibiao from '../../static/images/v-yibiao.png'

import Vdianchi from '../../static/images/v-dianchi.png'
import Vphone from '../../static/images/v-phone.png'
import Vcon from '../../static/images/v-con.png'
import Vcar from '../../static/images/v-car.png'
import Vonwear from '../../static/images/v-onwear.png'
import Vdown from '../../static/images/v-down.png'
import Vqr from '../../static/images/v-qr.png'
import Vpro from '../../static/images/v-pro.png'
import Vlanya from '../../static/images/v-lanya.png'
import Vmleft from '../../static/images/v-m-left.png'
import Vmright from '../../static/images/v-m-right.png'
import Vmup from '../../static/images/v-m-up.png'
import Vmdown from '../../static/images/v-m-down.png'
import VD from '../../static/images/v-d.png'
import VT from '../../static/images/v-t.png'
import Vleft from '../../static/images/v-left.png'
import Vright from '../../static/images/v-right.png'
import Vpt from '../../static/images/v-p-t.png'
import Vp3 from '../../static/images/v-p-3.png'
import Vp2 from '../../static/images/v-p-2.png'
import Vp1 from '../../static/images/v-p-1.png'
import Vtg from '../../static/images/v-t-g.png'
import Vm1 from '../../static/images/v-m-1.png'
import Vm2 from '../../static/images/v-m-2.png'
import Vm3 from '../../static/images/v-m-3.png'
import Vm4 from '../../static/images/v-m-4.png'

import Vh1 from '../../static/images/v-h-1.png'
import Vh2 from '../../static/images/v-h-2.png'
import Vh3 from '../../static/images/v-h-3.png'
import Vh4 from '../../static/images/v-h-4.png'

import Vs1 from '../../static/images/v-s-1.png'
import Vs2 from '../../static/images/v-s-2.png'
import Vs3 from '../../static/images/v-s-3.png'
import Vs4 from '../../static/images/v-s-4.png'

import Vw1 from '../../static/images/v-w-1.png'
import Vw2 from '../../static/images/v-w-2.png'
import Vw3 from '../../static/images/v-w-3.png'

import Vi1 from '../../static/images/v-i-1.png'
import Vi2 from '../../static/images/v-i-2.png'
import Vi3 from '../../static/images/v-i-3.png'
import Vi4 from '../../static/images/v-i-4.png'

import Vr1 from '../../static/images/v-r-1.png'

import Vo1 from '../../static/images/v-o-1.png'
import Vo2 from '../../static/images/v-o-2.png'
import Vo3 from '../../static/images/v-o-3.png'
import Vo4 from '../../static/images/v-o-4.png'

const mapStateToProps = () => {
    return {}
}
const mapDispatchToProps = {}
@connect(mapStateToProps, mapDispatchToProps)
class Index extends Component {
    state = {}

    render() {
        return (
            <div className="p-view">
                <CNav></CNav>

                <div className="p-view-header">
                    <img src={Vwatch} alt="" className='animate__animated  animate__fadeIn animate__slower'/>
                    <h3 className="pc animate__animated  animate__backInRight animate__slower">
                        Please read the <br />
                        instructions <br /> before use
                    </h3>
                    <h3 className="mobile animate__animated  animate__backInRight animate__slowe">
                        Please <br />
                        read the <br />
                        instructions <br /> before use
                    </h3>
                </div>

                <div className="p-view-cam">
                    <li>
                        <img src={VcamL} alt="" className="animate__animated  animate__backInLeft animate__slowe"/>
                    </li>
                    <li className='animate__animated  animate__bounceIn animate__slower'>
                        <div>
                            <img src={Vbee} alt="" />
                            <span>
                                The company reserves the right to modify the content of this notice without notice.According to normal circumstances, some functions are different in a specific
                                software version.
                            </span>
                        </div>
                        <div>
                            <img src={Vyibiao} alt="" />
                            <span>Please use the input voltage: 5V/1A or 5V/2A charging head, with the original charging cable provided by the manufacturer.</span>
                        </div>
                    </li>
                    <li className='animate__animated  animate__bounceIn animate__slower'>
                        <div>
                            <img src={Vdianchi} alt="" />
                            <span>
                                The company reserves the right to modify the content of this notice without notice.According to normal circumstances, some functions are different in a specific
                                software version.
                            </span>
                        </div>
                        <div>
                            <img src={Vphone} alt="" />
                            <span>Please use the input voltage: 5V/1A or 5V/2A charging head, with the original charging cable provided by the manufacturer.</span>
                        </div>
                    </li>
                    <li className='animate__animated  animate__bounceIn animate__slower'>
                        <div>
                            <img src={Vcon} alt="" />
                            <span>
                                The company reserves the right to modify the content of this notice without notice.According to normal circumstances, some functions are different in a specific
                                software version.
                            </span>
                        </div>
                        <div>
                            <img src={Vcar} alt="" />
                            <span>Please use the input voltage: 5V/1A or 5V/2A charging head, with the original charging cable provided by the manufacturer.</span>
                        </div>
                    </li>
                </div>

                

                <div className="p-view-operation">
                    <h3 className="p-view-h3">Method of operation</h3>
                    <div className="p-view-operation-box">
                        <li>
                            <div>
                                <img src={VD} alt="" />
                                <img src={Vmdown} alt="" className="m1" />
                            </div>

                            <span>From the watch screen, swipe down to go to display the shortcut page.</span>
                        </li>
                        <li>
                            <div>
                                <img src={VT} alt="" />
                                <img src={Vmup} alt="" className="m1" />
                            </div>
                            <span>Swipe up to display the listed information page.</span>
                        </li>
                        <li>
                            <div>
                                <img src={Vright} alt="" />
                                <img src={Vmright} alt="" className="m2" />
                            </div>
                            <span>Swipe right to display the menu.</span>
                        </li>
                        <li>
                            <div>
                                <img src={Vleft} alt="" />
                                <img src={Vmleft} alt="" className="m2" />
                            </div>
                            <span>Swipe left to display step count and heart rate pages.</span>
                        </li>
                    </div>
                </div>

                <div className="p-view-process">
                    <div className="p-view-process-step">
                        <h3 className="p-view-h3">Exercise process</h3>
                        <div className="p-view-text">
                            {`Please make sure to wear the watch tightly, after clicking “Let's Chill” in the cell phone CHILL APP, click the start button on the watch, the APP and the watch will
                            synchronize in a 321 countdown and then start the CHILL exercise.`}
                        </div>
                        <div className="p-view-process-step-imgs">
                            <img src={Vpt} alt="" />
                            <img src={Vp3} alt="" />
                            <img src={Vp2} alt="" />
                            <img src={Vp1} alt="" />
                            <img src={Vtg} alt="" />
                        </div>
                    </div>
                    <div className="p-view-process-step">
                        <h3 className="p-view-h3">Messages</h3>
                        <div className="p-view-text">After the watch is connected to the mobile app, new messages will be synced to the watch terminal. The watch will store multiple messages.</div>
                        <div className="p-view-process-step-imgs">
                            <img src={Vm1} alt="" />
                            <img src={Vm3} alt="" />
                            <img src={Vm2} alt="" />
                            <img src={Vm4} alt="" />
                        </div>
                    </div>
                    <div className="p-view-process-heart">
                        <div>
                            <h3 className="p-view-h3">
                                Heart Rate <br />
                                Monitoring
                            </h3>
                            <div className="p-view-text">
                                Heart Rate Measurement∶The measurement begins once a green light shines from the base of the watch, and is completed in 30 to 60 seconds. This measured value is based
                                on PPG technology.{' '}
                            </div>
                            <div className="p-view-process-heart-imgs">
                                <img src={Vh1} alt="" />
                                <img src={Vh2} alt="" />
                            </div>
                        </div>
                        <div>
                            <h3 className="p-view-h3">
                                Blood Oxygen <br />
                                Level Monitoring
                            </h3>
                            <div className="p-view-text">
                                Blood oxygen level measurement: The measurement begins once a red light shines from the base of the watch, and is completed in about 30~60 seconds. The measurement
                                value is based on PPG technology.
                            </div>
                            <div className="p-view-process-heart-imgs">
                                <img src={Vh3} alt="" />
                                <img src={Vh4} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="p-view-process-step">
                        <h3 className="p-view-h3">Sleep Monitoring</h3>
                        <div className="p-view-text">Displays sleep monitoring data for the day, which is updated daily. Individual data can be synced to the APP for viewing.</div>
                        <div className="p-view-process-step-imgs">
                            <img src={Vs1} alt="" />
                            <img src={Vs2} alt="" />
                            <img src={Vs3} alt="" />
                            <img src={Vs4} alt="" />
                        </div>
                    </div>

                    <div className="p-view-process-heart">
                        <div>
                            <h3 className="p-view-h3">Dial Switching</h3>
                            <div className="p-view-text">
                                BeeWatch has many astonishing dials, these dials are co-branded with famous Blue-Chip NFT, you can go to the dial center of CHILL APP, pick your favorite dials and sync
                                them to your watch!
                            </div>
                            <div className="p-view-process-heart-imgs">
                                <img src={Vw1} alt="" />
                                <img src={Vw2} alt="" />
                            </div>
                        </div>
                        <div>
                            <h3 className="p-view-h3">Powering On/Off</h3>
                            <div className="p-view-text">Press and hold the button for 3 seconds to power on. Tap the icon on the screen to power off.</div>
                            <div className="p-view-process-heart-imgs">
                                <img src={Vw3} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="p-view-process-heart">
                        <div>
                            <h3 className="p-view-h3">
                                Find your <br />
                                Phone
                            </h3>
                            <div className="p-view-text">
                                Click to find your mobile phone, you can find the location of the mobile phone through activating the ringtone, during which the ringing status will be displayed.
                            </div>
                            <div className="p-view-process-heart-imgs">
                                <img src={Vi1} alt="" />
                                <img src={Vi2} alt="" />
                            </div>
                        </div>
                        <div>
                            <h3 className="p-view-h3">
                                System <br />
                                Information
                            </h3>
                            <div className="p-view-text">View information such as the watch’s Bluetooth name, Mac address, and firmware version.</div>
                            <div className="p-view-process-heart-imgs">
                                <img src={Vi3} alt="" />
                                <img src={Vi4} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="p-view-process-step">
                        <h3 className="p-view-h3">Factory Reset</h3>
                        <div className="p-view-text">Performing a factory reset will clear all personal data.</div>
                        <div className="p-view-process-step-imgs">
                            <img src={Vr1} alt="" />
                        </div>
                    </div>
                </div>

                <div className="p-view-operation">
                    <h3 className="p-view-h3">Other Features</h3>
                    <div className="p-view-operation-box">
                        <li>
                            <div>
                                <img src={Vo1} alt="" />
                            </div>

                            <span>Low battery reminder</span>
                        </li>
                        <li>
                            <div>
                                <img src={Vo2} alt="" />
                            </div>
                            <span>Battery charging completed</span>
                        </li>
                        <li>
                            <div>
                                <img src={Vo3} alt="" />
                            </div>
                            <span>Updating data for upload</span>
                        </li>
                        <li>
                            <div>
                                <img src={Vo4} alt="" />
                            </div>
                            <span>Using your phone to find your watch</span>
                        </li>
                    </div>
                </div>

                <div className="p-view-parameters">
                    <h3 className="p-view-h3">Product Parameters</h3>
                    <div className="p-view-parameters-detail">
                        <div>
                            <li>Please read this manual first to ensure safe and correct use.</li>
                            <li style={{ marginTop: '20px' }}>
                                {' '}
                                •The data displayed by this product serves only as reference for health management. Please do not treat it as medical data. If you find abnormal physical data, you
                                should seek medical attention.
                            </li>
                            <li style={{ marginTop: '20px' }}>
                                •The waterproof rating of the watch is 3ATM. You may use the watch normally in daily scenarios such as washing your hands and being in the rain. Do not put the watch in
                                hot water, do not press buttons underwater or dive deep while wearing the watch, and do not touch the watch with corrosive liquid so as to prevent damage to the watch.
                            </li>
                        </div>

                        <div>
                            <li>
                                <span>Waterproof Rating</span>
                                <span>3ATM</span>
                            </li>
                            <li>
                                <span>Sensor</span>
                                <span>Three-axis sensor</span>
                            </li>
                            <li>
                                <span>Resolution</span>
                                <span>320*390</span>
                            </li>
                            <li>
                                <span>Sync mode</span>
                                <span>Bluetooth 5.1</span>
                            </li>
                            <li>
                                <span>Components in package</span>
                                <span>Main device, charging cable</span>
                            </li>
                            <li>
                                <span>Compatible Systems</span>
                                <span>IOS11.0 and above、Android6.0 and above</span>
                            </li>
                            <li>
                                <span>Display Screen</span>
                                <span>1.8-inch IPSTFT</span>
                            </li>
                            <li>
                                <span>Working time</span>
                                <span>About 7-10 days</span>
                            </li>
                            <li>
                                <span>Working temperature</span>
                                <span>-10°C~50°C</span>
                            </li>
                        </div>
                    </div>
                </div>
				<div className="p-view-app">
                    <div className="p-view-app-left">
                        <div className="top">
                            <span>
                                APP <br />
                                download <br />
                                and login
                            </span>
                            <img src={Vqr} alt="" className="pc" />
                            {/* 
                            <div>
                                <p>APP download method "OnWear Pro": </p>
                                <p>1.Apple mobile phones can be downloaded in the App Store.</p>
                                <p>2.Android phones can be downloaded in Google play store and app treaure.</p>
                                <p>3.Or scan the QR code in the watch to download the APP (compatible: IOS11.0+ or Android6.0+)</p>
                            </div> */}
                        </div>
                        <div className="bottom">
                            <div className="left">
                                <img src={Vonwear} alt="" />
                                <div>
                                    <p>{`APP download method "OnWear Pro":`} </p>
                                    <p>1.Apple mobile phones can be downloaded in the App Store.</p>
                                    <p>2.Android phones can be downloaded in Google play store and app treaure.</p>
                                    <p>3.Or scan the QR code in the watch to download the APP (compatible: IOS11.0+ or Android6.0+)</p>
                                </div>
                            </div>
                            <div className="right">
                                <div>
                                    <img src={Vdown} alt="" />
                                    <span>Install the APP, please register and log in according to the interface prompts, you need to turn on the Bluetooth.</span>
                                </div>
                                <div>
                                    <img src={Vlanya} alt="" />
                                    <span>In the device interface, select the Bluetooth name W13 for binding, and the watch can be used normally.</span>
                                </div>
                            </div>
                        </div>
                        <img src={Vqr} alt="" className="mobile vqr" />
                    </div>

                    <div className="p-view-app-right">
                        <img src={Vpro} alt="" />
                    </div>
                </div>
                <div className="mobile mobilefooter">
                    <CFooter />
                </div>
            </div>
        )
    }
}
export default Index
